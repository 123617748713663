import { FC, MouseEvent, memo, useCallback } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { ArrowDownward as ArrowDownwardIcon, ArrowUpward as ArrowUpwardIcon } from '@mui/icons-material';

import { Environment } from '@playq/octopus-common';
import {
  NotificationWithEnvironmentStruct,
  NotificationWithPromotionSupportStruct,
} from '@playq/octopus-notifications';

import { IActionMessageProps } from './types';
import {
  AddIconStyled,
  DeleteIconStyled,
  EditIconStyled,
  LabelThemedStyled,
  LinkStyled,
  useActionMessageStyles,
} from './styles';

export const ActionMessage: FC<IActionMessageProps> = memo(({ notification, title, link, StartIcon }) => {
  const classes = useActionMessageStyles();

  const notificationClass = notification.getClassName();
  const ActionIcon = notificationClass.endsWith('Created')
    ? AddIconStyled
    : notificationClass.endsWith('Updated')
    ? EditIconStyled
    : notificationClass.endsWith('Deleted')
    ? DeleteIconStyled
    : undefined;

  const notificationWithEnv = NotificationWithEnvironmentStruct.isRegisteredType(notification.getFullClassName())
    ? (notification as NotificationWithEnvironmentStruct)
    : undefined;
  const PromotionIcon = notificationClass.endsWith('Promoted')
    ? ArrowUpwardIcon
    : notificationClass.endsWith('Demoted')
    ? ArrowDownwardIcon
    : undefined;

  const notificationWithSpace = NotificationWithPromotionSupportStruct.isRegisteredType(notification.getFullClassName())
    ? (notification as NotificationWithPromotionSupportStruct)
    : undefined;

  const handleClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {StartIcon && <StartIcon fontSize='small' className={classes.entityIcon} />}
      {link && !notificationClass.endsWith('Deleted') ? (
        <LinkStyled title={title?.toString()} to={link} onClick={handleClick}>
          {title}
        </LinkStyled>
      ) : (
        <Typography title={title?.toString()} noWrap={true} variant='inherit'>
          {title}
        </Typography>
      )}
      {notificationWithEnv && (
        <Tooltip title={notificationWithSpace?.spaceName || 'Default'} placement='top'>
          <LabelThemedStyled env={notificationWithEnv.env} outline={notificationClass.endsWith('Demoted')}>
            {PromotionIcon && <PromotionIcon fontSize='small' />}
            {notificationWithSpace &&
              notificationWithEnv.env === Environment.Sandbox &&
              notificationWithSpace.spaceName && (
                <Typography variant='inherit' noWrap={true}>
                  {notificationWithSpace.spaceName}
                </Typography>
              )}
          </LabelThemedStyled>
        </Tooltip>
      )}
      {ActionIcon && <ActionIcon fontSize='small' />}
    </>
  );
});
