import { App } from '@playq/octopus2-apps';

import { createStorage } from '/storage';
import { KBAR_APPS } from '/common/Kbar/constants';
import { IStorageApp, IStorageAppSerialized } from '/common/Kbar/types';

export const setAppToKbar = (app: App) => {
  let selectedApp: IStorageApp;
  const storage = createStorage();
  const appsFromStorage = storage.get(KBAR_APPS);

  const favoriteApps: IStorageApp[] =
    appsFromStorage !== null
      ? (JSON.parse(appsFromStorage) as IStorageAppSerialized[]).map((storedApp) => {
          return {
            app: new App(storedApp.app),
            counter: storedApp.counter,
          };
        })
      : [];

  const existAppIndex: number = favoriteApps.findIndex((selected) => {
    return selected.app.fingerprintID.app === app.fingerprintID.app;
  });

  if (existAppIndex >= 0) {
    selectedApp = favoriteApps[existAppIndex];

    selectedApp.app.name = app.name;
    selectedApp.counter += 1;
  } else {
    selectedApp = {
      app: app,
      counter: 0,
    };
    favoriteApps.push(selectedApp);
  }

  storage.set(
    KBAR_APPS,
    JSON.stringify(
      favoriteApps.map((favApp) => ({
        app: favApp.app.serialize(),
        counter: favApp.counter,
      }))
    )
  );
};

export const deleteAppFromKbar = (app: App) => {
  const storage = createStorage();
  const appsFromStorage = storage.get(KBAR_APPS);

  const favoriteApps: IStorageApp[] =
    appsFromStorage !== null
      ? (JSON.parse(appsFromStorage) as IStorageAppSerialized[]).map((storedApp) => {
          return {
            app: new App(storedApp.app),
            counter: storedApp.counter,
          };
        })
      : [];

  if (favoriteApps.length === 0) {
    return;
  }

  const filteredApps = favoriteApps.filter((favApp) => favApp.app.fingerprintID.app !== app.fingerprintID.app);

  storage.set(
    KBAR_APPS,
    JSON.stringify(
      filteredApps.map((favApp) => ({
        app: favApp.app.serialize(),
        counter: favApp.counter,
      }))
    )
  );
};
