export const KBAR_SECTION = {
  PREFERENCES: 'preferences',
  SHORTCUTS: 'shortcuts',
  APPS_ACTION: 'action',
  FAV_APP: 'favorite apps',
  NAVIGATION: 'navigation',
};

export const KBAR_STORAGE_PREFIX = 'kbar';
export const KBAR_APPS = `${KBAR_STORAGE_PREFIX}_favoriteApps`;
