import { useRegisterActions } from 'kbar';
import { Cloud as CloudIcon, GitHub as GitHubIcon } from '@mui/icons-material';

import { KBAR_SECTION } from '/common/Kbar/constants';

export const NavigationActions = () => {
  useRegisterActions([
    {
      id: 'githubAction',
      name: 'GitHub',
      keywords: 'git github pr',
      section: KBAR_SECTION.NAVIGATION,
      icon: <GitHubIcon />,
      perform: () => window.open('https://github.com/PlayQ/tg-octopus', '_blank'),
    },
    {
      id: 'teamCityAction',
      name: 'TeamCity',
      keywords: 'tc teamcity deploy',
      section: KBAR_SECTION.NAVIGATION,
      icon: <CloudIcon />,
      perform: () => window.open('https://tc.playq.net/project/tgportal_Octopus?mode=builds', '_blank'),
    },
  ]);

  return null;
};
