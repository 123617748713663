import { useRegisterActions } from 'kbar';
import { useSelector } from 'react-redux';
import { DoubleArrow as DoubleArrowIcon } from '@mui/icons-material';
import { useMemo } from 'react';
import _ from 'lodash';

import { AccessLevel, PermModule } from '@playq/octopus2-auth';

import { snackbarService } from '/common/snackbarService';
import { KBarSnackBar } from '/common/Kbar/KBarSnackBar';
import { history } from '/Router/history';
import { appToolkit, authToolkit } from '/store';
import { createNavConfig, INavItem } from '/common/NavSidebar';
import { KBAR_SECTION } from '/common/Kbar/constants';

export const ModuleAction = () => {
  const appName = useSelector(appToolkit.selectors.appRouteName);
  const role = useSelector(authToolkit.selectors.role);
  const access: Record<string, AccessLevel> = useSelector(appToolkit.selectors.accessScope);

  const routeList: INavItem[] = useMemo(() => {
    return createNavConfig({
      appName,
      role,
      access,
    });
  }, [appName, role, access]);

  const sectionSelector = (module: PermModule | undefined) =>
    module === undefined || module === 'None' ? 'General' : module;

  const handleRouterRedirect = (route: string) => {
    history.push(route);
  };

  const routeActions = routeList.map((route) => {
    if (Object.hasOwn(route, 'children') && route.children !== undefined) {
      return route.children.map((childRoute) => ({
        id: `${childRoute.caption}Action`,
        name: `${childRoute.caption}`,
        keywords: `${childRoute.caption}`,
        section: `${route.caption}`,
        parent: 'goToAction',
        perform: () => {
          if (childRoute.route === undefined) {
            return;
          }

          handleRouterRedirect(childRoute.route);

          snackbarService.custom((key) => (
            <KBarSnackBar
              key={key}
              closeKey={key}
              data-testid='undo-button'
              action={() => history.goBack()}
              buttonText='Undo'
              title={`Successful redirect to: ${_.capitalize(childRoute.caption)}`}
            />
          ));

          return () => history.goBack();
        },
      }));
    } else {
      return {
        id: `${route.caption}Action`,
        name: `${route.caption}`,
        keywords: `${route.caption}`,
        section: sectionSelector(route.module),
        parent: 'goToAction',
        perform: () => {
          if (route.route === undefined) {
            return;
          }

          handleRouterRedirect(route.route);

          snackbarService.custom((key) => (
            <KBarSnackBar
              key={key}
              closeKey={key}
              action={() => history.goBack()}
              data-testid='undo-button'
              buttonText='Undo'
              title={`Successful redirect to: ${_.capitalize(route.caption)}`}
            />
          ));

          return () => history.goBack();
        },
      };
    }
  });

  useRegisterActions(
    [
      {
        id: 'goToAction',
        name: 'Go to',
        keywords: 'g go to module select goto',
        section: KBAR_SECTION.SHORTCUTS,
        icon: <DoubleArrowIcon />,
        shortcut: ['g'],
        priority: 1,
      },
      ...routeActions.flat(),
    ],

    [appName, role, access]
  );

  return null;
};
