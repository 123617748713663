import { ReactElement, Suspense } from 'react';
import { Backdrop } from '@mui/material';

import { PageLoaderSpinner } from '/shared/Spinner';

import { FCWithChildren } from './models';

export const DefaultFallback = (
  <Backdrop open={true}>
    <PageLoaderSpinner />
  </Backdrop>
);
export interface ILoadableProps {
  fallback?: ReactElement;
}
export const Loadable: FCWithChildren<ILoadableProps> = ({ fallback = DefaultFallback, children }) => {
  return <Suspense fallback={fallback}>{children}</Suspense>;
};
