import { styled, Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

import { headerHeight } from '../Header/styles';

export const navSidebarWidth = 240;
export const collapsedNavSidebarWidth = 56;

export const styles = (theme: Theme) =>
  createStyles({
    sidebar: {
      flexShrink: 0,
      whiteSpace: 'nowrap',
      width: navSidebarWidth,
      zIndex: theme.zIndex.drawer,
    },
    sidebarClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      }),
      width: collapsedNavSidebarWidth,
      overflow: 'hidden',
    },
    sidebarOpen: {
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.easeOut,
      }),
      width: navSidebarWidth,
      overflowX: 'hidden',
    },
    paper: {
      marginTop: headerHeight - 20,
      paddingTop: 20,
      height: `calc(100% - ${headerHeight - 20}px)`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingBottom: 10,
    },
  });

export const VersionSection = styled('div')`
  margin-right: 10px;
  text-align: right;
  min-height: 66px;

  &::selection {
    background: #ff5722;
    color: white;
  }

  & > span {
    opacity: 0.5;
  }
`;
