import { useRegisterActions } from 'kbar';
import { useCallback, useContext, useMemo } from 'react';
import _ from 'lodash';
import { Brightness4 as BrightnessIcon } from '@mui/icons-material';

import { KBAR_SECTION } from '/common/Kbar/constants';
import { KBarSnackBar } from '/common/Kbar/KBarSnackBar/KBarSnackBar';
import { snackbarService } from '/common/snackbarService';
import { ThemeModeContext } from '/common';
import { IThemeModeContext, ThemeMode } from '/common/models';

export const ThemeActions = () => {
  const { currentThemeMode, updateThemeMode }: IThemeModeContext = useContext(ThemeModeContext);
  const isDarkMode = useMemo(() => currentThemeMode === ThemeMode.Dark, [currentThemeMode]);

  const updateThemeModeHandler = useCallback(
    (themeMode = isDarkMode) => {
      return themeMode ? updateThemeMode(ThemeMode.Light) : updateThemeMode(ThemeMode.Dark);
    },
    [isDarkMode, updateThemeMode]
  );
  useRegisterActions([
    {
      id: 'themeAction',
      name: `Change theme: ${_.capitalize(isDarkMode ? ThemeMode.Light : ThemeMode.Dark)}`,
      keywords: 'interface color dark light',
      section: KBAR_SECTION.PREFERENCES,
      shortcut: ['t'],
      icon: <BrightnessIcon />,
      perform: () => {
        updateThemeModeHandler();
        snackbarService.custom((key) => (
          <KBarSnackBar
            key={key}
            closeKey={key}
            action={() => updateThemeModeHandler(!isDarkMode)}
            data-testid='undo-button'
            buttonText='Undo'
            title={`${_.capitalize(isDarkMode ? ThemeMode.Light : ThemeMode.Dark)} theme enabled`}
          />
        ));

        return () => updateThemeModeHandler(!isDarkMode);
      },
    },
  ]);

  return null;
};
