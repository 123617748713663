import { makeStyles } from '@mui/styles';
import { Palette, Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  const themePalette: Palette = theme.palette;

  return {
    item: {
      textTransform: 'none',
    },
    link: {
      textDecoration: 'none',
      color: themePalette.text.primary,
      '&:hover': {
        color: themePalette.text.primary,
      },
    },
  };
});
