import { FC, useMemo } from 'react';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Chip, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { Class as NotificationClass } from '@playq/octopus-notifications';

import { INotificationGroupProps } from './types';
import { getNotificationIcon } from './getNotificationIcon';

export const NotificationGroup: FC<INotificationGroupProps> = ({ group, expandedItems, onToggle }) => {
  const expanded = expandedItems.includes(group.title);
  const hasItems = group.items.length > 0;

  const Icon = useMemo(() => getNotificationIcon(group.title as NotificationClass), [group]);

  const handleToggle = () => {
    if (hasItems) {
      onToggle(group);
    }
  };

  return (
    <ListItem onClick={handleToggle} button={true}>
      {Icon && (
        <ListItemIcon>
          <Icon fontSize='small' />
        </ListItemIcon>
      )}

      <ListItemText primary={group.title} />
      <Chip label={group.items.length} size='small' />
      {hasItems && (expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
    </ListItem>
  );
};
