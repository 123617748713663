import { alpha, Checkbox, Select, styled } from '@mui/material';

export const FilterTab = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'selected' && prop !== 'mainColor',
})<{ active: boolean; selected: boolean; mainColor: string }>(({ theme, active, selected, mainColor }) => ({
  zIndex: 0,
  position: 'relative',
  padding: '2px',
  backgroundColor: theme.palette.background.paper,
  border: '1px solid transparent',
  borderBottom: 'none',
  borderTopLeftRadius: '50%',
  borderTopRightRadius: '50%',
  '&:first-of-type': {
    marginLeft: theme.spacing(1),
  },
  ...(selected && {
    zIndex: 2,
    borderColor: `${theme.palette.divider} ${theme.palette.divider} transparent`,
    '&:before': {
      content: '""',
      width: 'calc(100% + 16px)',
      height: '10px',
      bottom: '-3px',
      left: '-8px',
      position: 'absolute',
      background: `radial-gradient(circle at top left, transparent 6px, ${theme.palette.divider}, ${theme.palette.background.paper} 8px, transparent),
                    radial-gradient(circle at top right, transparent 6px, ${theme.palette.divider}, ${theme.palette.background.paper} 8px, transparent)`,
    },
  }),
  ...(active && {
    '.MuiIconButton-root': {
      color: mainColor,
      '&:hover': {
        backgroundColor: alpha(mainColor, theme.palette.action.hoverOpacity),
      },
    },
  }),
}));

// FilterContent component without styled components
export const FilterContent = styled('div', {
  shouldForwardProp: (prop) => prop !== '$expanded',
})<{ $expanded?: boolean }>(({ theme, $expanded }) => ({
  zIndex: 1,
  minHeight: '61px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  padding: `0 ${theme.spacing(1)}`,
  border: `1px solid ${theme.palette.divider}`,
  borderLeft: 'none',
  borderRight: 'none',
  ...($expanded && {
    minHeight: 'unset',
    gap: theme.spacing(2),
    margin: 0,
    padding: theme.spacing(2),
  }),
}));

// Other components refactored similarly
export const FilterColumn = styled('div')({
  flex: 1,
});

export const DateFilterColumn = styled(FilterColumn)({
  width: '330px',
  flex: 'unset',
});

export const AuthorFilterColumn = styled(FilterColumn)(() => ({
  '.MuiOutlinedInput-root': {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '14px',
    '.MuiAutocomplete-input:first-child': {
      padding: '14px',
      paddingLeft: 0,
    },
  },
}));

export const RightActions = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingRight: theme.spacing(1),
}));

export const EnvSpaceWrapper = styled('div')({
  alignSelf: 'stretch',
  paddingTop: '2px',
});

export const CheckboxStyled = styled(Checkbox)({
  padding: 0,
  margin: '0 9px',
});

export const GroupByFilterColumn = styled(FilterColumn, {
  shouldForwardProp: (prop) => prop !== '$expanded',
})<{ $expanded?: boolean }>(({ $expanded }) => ({
  maxWidth: $expanded ? '200px' : 'unset',
}));

export const SelectStyled = styled(Select)({
  minWidth: '150px',
  '.MuiSelect-iconOutlined': {
    right: '0px',
  },
});
