import { Button, CardContent, styled } from '@mui/material';

export const StyledSnackBarContent = styled(CardContent)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  min-width: 300px;
  border-radius: 4px;
  background-color: #495057;

  &:last-child {
    padding-bottom: 10px;
  }

  ${({ theme }) => `
    color: ${theme.palette.common.white};
  `}
`;

export const StyledSnackBarButton = styled(Button)`
  margin-left: 30px;
`;
