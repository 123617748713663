import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { List, useMediaQuery, useTheme } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';

import { AccessLevel } from '@playq/octopus2-auth';

import { appToolkit, authToolkit, deviceToolkit, RootState, uiToolkit } from '/store';
import { history } from '/Router/history';
import { IManualTogglingParams, Sidebar, SidebarSide } from '/shared/Sidebar';

import { INavSidebarProps, INavSidebarStateProps } from './types';
import { getSelectedItems } from './helpers';
import { NavSidebarItem } from './NavSidebarItem';
import { createNavConfig, INavItem, statusMonitorNavConfig } from './navConfig';
import { styles, VersionSection } from './styles';

const NavSidebarComponent: FC<INavSidebarProps> = memo((props) => {
  const { classes, role, appName, location, onOpeningStatusChange } = props;

  const access: Record<string, AccessLevel> = useSelector(appToolkit.selectors.accessScope);
  const isSidebarPermanentlyOpen: boolean = useSelector(uiToolkit.selectors.navSidebarOpen);
  const sidebarSide: SidebarSide = useSelector(uiToolkit.selectors.navSidebarSide);
  const device = useSelector(deviceToolkit.selectors.devices);

  const routerPath = useRef('');
  const [selectedItems, setSelectedItems] = useState(() => new Set<string>([]));
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(isSidebarPermanentlyOpen);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const toggleNavBarPermanentlyOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(uiToolkit.actions.toggleNavSidebar(isOpen));
    },
    [dispatch]
  );

  const sidebarClassName = `${classes.sidebar} ${isSidebarOpen ? classes.sidebarOpen : classes.sidebarClose}`;

  const getSelectedItemsByPath = useCallback(
    (items: INavItem[]) => {
      const itemsFullList: INavItem[] = [...items, statusMonitorNavConfig];

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (location?.pathname && itemsFullList) {
        const foundItems = getSelectedItems(itemsFullList, location.pathname) || [];
        setSelectedItems(new Set(foundItems));
        routerPath.current = location.pathname;
      }
    },
    [location?.pathname]
  );

  const onSelectItems = (captions: string[]) => setSelectedItems(new Set(captions));

  const handleRouterRedirect = (route: string) => {
    routerPath.current = route;
    history.push(route);
  };

  const list: INavItem[] = useMemo(() => {
    const newList = createNavConfig({
      appName,
      role,
      access,
      hidden: device[0] === undefined,
    });
    getSelectedItemsByPath(newList);
    return newList;
  }, [appName, role, access, device, getSelectedItemsByPath]);

  const classesProps = useMemo(
    () => ({
      paper: classNames(classes.paper, sidebarClassName),
    }),
    [classes, sidebarClassName]
  );

  const sidebarManualTogglingParams = useMemo<IManualTogglingParams>(
    () => ({
      isOpenedManually: isSidebarPermanentlyOpen,
      openManually: () => toggleNavBarPermanentlyOpen(true),
      closeManually: () => toggleNavBarPermanentlyOpen(false),
    }),
    [isSidebarPermanentlyOpen, toggleNavBarPermanentlyOpen]
  );

  const handleSidebarTogglingStatusChange = useCallback((isOpen: boolean) => {
    setIsSidebarOpen(isOpen);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (location && routerPath && location.pathname !== routerPath.current) {
      getSelectedItemsByPath(list);
    }
  }, [getSelectedItemsByPath, list, location, location?.pathname]);

  useEffect(() => {
    onOpeningStatusChange(isSidebarOpen);
  }, [isSidebarOpen, onOpeningStatusChange]);

  return (
    <Sidebar
      side={sidebarSide}
      manualTogglingParams={sidebarManualTogglingParams}
      onTogglingStatusChange={handleSidebarTogglingStatusChange}
      shouldBeOpenOnHover={true}
      variant={matches ? 'temporary' : 'permanent'}
      className={sidebarClassName}
      classes={classesProps}
    >
      <List component='nav'>
        {list.map((item) => (
          <NavSidebarItem
            key={item.caption}
            item={item}
            openSidebar={isSidebarOpen}
            selectedItems={selectedItems}
            onSelectItem={onSelectItems}
            onRouterRedirect={handleRouterRedirect}
          />
        ))}
      </List>
      <VersionSection>
        <NavSidebarItem
          key={statusMonitorNavConfig.caption}
          item={statusMonitorNavConfig}
          openSidebar={isSidebarOpen}
          selectedItems={selectedItems}
          onSelectItem={onSelectItems}
          onRouterRedirect={handleRouterRedirect}
        />
        <span>{isSidebarOpen && process.env.NODE_ENV === 'production' && `v${__CI_BUILD__}`}</span>
      </VersionSection>
    </Sidebar>
  );
});

const mapStateToProps = (state: RootState): INavSidebarStateProps => ({
  role: authToolkit.selectors.role(state),
  appName: appToolkit.selectors.appRouteName(state),
});

export const NavSidebar = withStyles(styles)(connect(mapStateToProps)(NavSidebarComponent));
