import { KBarResults, useMatches } from 'kbar';
import React from 'react';

import {
  KBarKBDStyled,
  KBarResultGroupName,
  KBarResultGroupSymbol,
  KBarResultIcon,
  KBarResultItemName,
  KBarResultItemNameSection,
  KBarResultItemWrapper,
  KBarResultShortcutWrapper,
  KBarResultSubtitle,
  StyledKBarGroupResult,
} from '/common/Kbar/styles';
import { IResultItemProps } from '/common/Kbar/types';

const ResultItem = React.forwardRef(
  ({ action, active, currentRootActionId }: IResultItemProps, ref: React.Ref<HTMLDivElement>) => {
    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors;
      const index = action.ancestors.findIndex((ancestor) => ancestor.id === currentRootActionId);
      // +1 removes the currentRootAction; e.g.
      // if we are on the "Set theme" parent action,
      // the UI should not display "Set theme… > Dark"
      // but rather just "Dark"
      return action.ancestors.slice(index + 1);
    }, [action.ancestors, currentRootActionId]);

    return (
      <KBarResultItemWrapper ref={ref} active={active}>
        <KBarResultItemNameSection>
          {action.icon !== undefined && <KBarResultIcon>{action.icon}</KBarResultIcon>}
          <KBarResultItemName>
            <div>
              {ancestors.length > 0 &&
                ancestors.map((ancestor) => (
                  <React.Fragment key={ancestor.id}>
                    <KBarResultGroupName>{ancestor.name}</KBarResultGroupName>
                    <KBarResultGroupSymbol>&rsaquo;</KBarResultGroupSymbol>
                  </React.Fragment>
                ))}
              <span>{action.name}</span>
            </div>
            {action.subtitle && <KBarResultSubtitle>{action.subtitle}</KBarResultSubtitle>}
          </KBarResultItemName>
        </KBarResultItemNameSection>
        {action.shortcut?.length !== undefined ? (
          <KBarResultShortcutWrapper aria-hidden>
            {action.shortcut.map((sc) => (
              <KBarKBDStyled key={sc}>{sc}</KBarKBDStyled>
            ))}
          </KBarResultShortcutWrapper>
        ) : null}
      </KBarResultItemWrapper>
    );
  }
);

export const CommandBarResult = () => {
  const { results, rootActionId } = useMatches();

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === 'string' ? (
          <StyledKBarGroupResult>{item}</StyledKBarGroupResult>
        ) : (
          <ResultItem action={item} active={active} currentRootActionId={rootActionId ?? ''} />
        )
      }
    />
  );
};
