import { GenericFailure } from '@playq/services-shared';

export const createRequestQueue = <PayloadType, ResultType = undefined>(
  handler: (items: PayloadType[]) => Promise<(ResultType | undefined)[]> | void,
  options?: {
    predicate?: (data: PayloadType, result: ResultType | undefined) => boolean;
    timeout?: number;
  }
) => {
  let timerId: number | undefined;
  let items: {
    data: PayloadType;
    resolve: (data: ResultType | undefined) => void;
    reject: (err: GenericFailure) => void;
  }[] = [];

  return {
    push: (payload: PayloadType) => {
      return new Promise<ResultType | undefined>((resolve, reject) => {
        window.clearTimeout(timerId);
        items.push({ data: payload, resolve, reject });
        timerId = window.setTimeout(() => {
          const data = [...items];
          items = [];
          const res = handler(data.map((item) => item.data));
          if (res && options?.predicate !== undefined) {
            res
              .then((result) => {
                data.forEach((item) => {
                  const resultItem = result.find((r) => options?.predicate?.(item.data, r));
                  item.resolve(resultItem);
                });
              })
              .catch((e) => data.forEach((item) => item.reject(e)));
          }
        }, options?.timeout ?? 100);
      });
    },
  };
};
