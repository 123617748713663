import { useCallback } from 'react';
import { Updater, QueryKey, QueryClient } from '@tanstack/react-query';

import { OffsetLimit } from '@playq/services-shared';

/**
 * @template R - a successful response type.
 * @template ID - entity ID type.
 */
export type MutateCustomFieldsArgs<R extends object, ID extends { serialize: () => string }> = {
  response: R;
  nextResponse?: R;
  id?: ID;
};

/**
 * @template R - a successful response type.
 * @template ID - entity ID type.
 * @template E - entity type.
 */
export type MutateQueryDataAfterEntityDeletingArgs<R extends object, ID extends { serialize: () => string }, E> = {
  queryClient: QueryClient;
  nextPageQueryKey?: QueryKey;
  total: number;
  iterator: OffsetLimit;
  mutate: (updater: Updater<R | undefined, R | undefined>) => void;
  refetch: () => void;
  getID: (entity?: E) => ID | undefined;
  getEntities: (r: R) => E[];
  setEntities: (response: R, newEntities: E[]) => void;
  createNewResponse: (prevResponse: R, removedEntityID?: ID | ID[]) => R;
  mutateCustomFields?: (args: MutateCustomFieldsArgs<R, ID>) => void;
};

export const useMutateQueryDataAfterEntityDeleting = <R extends object, ID extends { serialize: () => string }, E>(
  args: MutateQueryDataAfterEntityDeletingArgs<R, ID, E>
) => {
  const {
    queryClient,
    nextPageQueryKey,
    total,
    iterator,
    mutate,
    refetch,
    getID,
    getEntities,
    setEntities,
    createNewResponse,
    mutateCustomFields,
  } = args;
  const { offset, limit } = iterator;

  return useCallback(
    (entityId: ID | ID[]) => {
      const ids = Array.isArray(entityId) ? entityId : [entityId];
      const nextResponse = !nextPageQueryKey ? undefined : queryClient.getQueryData<R | undefined>(nextPageQueryKey);

      mutate((prevResponse) => {
        if (prevResponse === undefined) {
          refetch();
          return;
        }

        const prevEntities = getEntities(prevResponse);
        const filteredEntities = prevEntities.filter(
          (entity) => !ids.find((id) => getID(entity)?.serialize?.() === id?.serialize?.())
        );

        const newTotal = total - 1;

        const response = createNewResponse(prevResponse, ids);
        setEntities(response, filteredEntities);
        if ('total' in response) {
          response.total = newTotal;
        }

        if (nextResponse === undefined) {
          if (newTotal > limit + offset) {
            refetch();
          }
          return response;
        }

        const entities = getEntities(response);
        const nextEntities = getEntities(nextResponse);

        let nextEntityIdx = 0;
        while (entities.length < limit && nextEntityIdx < nextEntities.length) {
          const lastEntity = entities[entities.length - 1];
          const lastEntityID = getID(lastEntity)?.serialize?.();
          const nextEntity = nextEntities[nextEntityIdx];
          const nextEntityID = getID(nextEntity);
          if (nextEntityID !== undefined && nextEntityID.serialize() !== lastEntityID) {
            entities.push(nextEntity);
            if (mutateCustomFields) {
              mutateCustomFields({ response, nextResponse, id: nextEntityID });
            }
          }
          nextEntityIdx += 1;
        }

        return response;
      });
    },
    [
      total,
      offset,
      limit,
      queryClient,
      nextPageQueryKey,
      mutate,
      refetch,
      getID,
      createNewResponse,
      getEntities,
      setEntities,
      mutateCustomFields,
    ]
  );
};
