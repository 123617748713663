import { Action, useRegisterActions } from 'kbar';
import { useSelector } from 'react-redux';
import { useMemo, useState, useCallback } from 'react';
import _ from 'lodash';
import { useEventCallback } from '@mui/material';
import { Apps as AppsIcon, DeleteForever as DeleteForeverIcon, Search as SearchIcon } from '@mui/icons-material';

import { GenericFailure, GenericFailureCode } from '@playq/services-shared';
import { App, AppFeature } from '@playq/octopus2-apps';
import { AppID } from '@playq/octopus-common';

import { deleteAppFromKbar, setAppToKbar } from '/common/Kbar/helpers';
import { snackbarService } from '/common/snackbarService';
import { KBarSnackBar } from '/common/Kbar/KBarSnackBar';
import { AppIcon } from '/component/Apps/AppIcon';
import { useStorage } from '/hooks';
import { KBAR_APPS, KBAR_SECTION } from '/common/Kbar/constants';
import { appToolkit, kbarToolkit, useAppDispatch } from '/store';
import { selectApp as selectAppAction } from '/store/toolkits/app/actions';
import { IStorageAppSerialized } from '/common/Kbar/types';
import { throwGenericFailure } from '/helpers';
import { services2 } from '/api';

export const ShortcutsAction = () => {
  const dispatch = useAppDispatch();
  const storage = useStorage();
  const app = useSelector(appToolkit.selectors.app) as App;

  const [prevApp, setPrevApp] = useState<App>(app);

  const favAppsRaw = storage.get(KBAR_APPS);

  const rollBackPrevApp = useEventCallback(() => dispatch(selectAppAction({ app: prevApp })));

  const handlerFailure = useCallback((err: Error | GenericFailure) => {
    console.error(err.message);
    snackbarService.error(`AppFetch: ${err.message}`);
  }, []);

  const favAppsSorted = useMemo(() => {
    if (favAppsRaw === null) {
      return [];
    }

    return JSON.parse(favAppsRaw) as IStorageAppSerialized[];
  }, [favAppsRaw]);

  const parentSelectAppAction: Action = useMemo(
    () => ({
      id: 'selectAppAction',
      name: 'Apps',
      keywords: 'select app',
      section: KBAR_SECTION.SHORTCUTS,
      shortcut: ['s', 'a'],
      subtitle: 'Select from you favorite app',
      icon: <AppsIcon />,
    }),
    []
  );

  const clearFavApps = useMemo(
    (): Action => ({
      id: 'clearFavAppsAction',
      name: 'Clear favorite apps',
      keywords: 'clear favorite app',
      section: KBAR_SECTION.APPS_ACTION,
      parent: 'selectAppAction',
      subtitle: 'After clear, list will be updated after you select app from octopus UI by standard method',
      icon: <DeleteForeverIcon />,
      priority: 0,
      perform: () => {
        storage.remove(KBAR_APPS);
        snackbarService.custom((key) => (
          <KBarSnackBar key={key} closeKey={key} title='List of favorite apps was successfully cleared' />
        ));
      },
    }),
    [storage]
  );

  const selectApp = useMemo((): Action[] => {
    const basicAppSelect = {
      ...parentSelectAppAction,
      name: 'Select app',
      id: 'selectAppModalAction',
      parent: 'selectAppAction',
      subtitle: 'Open modal for app select',
      section: KBAR_SECTION.APPS_ACTION,
      priority: 0,
      perform: () => {
        setTimeout(() => dispatch(kbarToolkit.actions.setOpenSelectAppModal(true)), 100);
      },
    };

    if (favAppsSorted.length === 0) {
      return [basicAppSelect];
    }

    const appsList: Action[] = favAppsSorted.map((favApp) => ({
      id: `${favApp.app.id}Action`,
      name: _.capitalize(favApp.app.name),
      keywords: favApp.app.name,
      section: KBAR_SECTION.FAV_APP,
      parent: 'selectAppAction',
      priority: favApp.counter,
      icon: <AppIcon app={new App(favApp.app)} lessShadow={true} />,
      perform: () => {
        const selected = new AppID(favApp.app.id);
        services2.appsService
          .retrieve(selected, [AppFeature.FingerprintSalts])
          .then((data) => {
            data.bifold(
              (retrievedApp) => {
                setPrevApp(app);
                setAppToKbar(retrievedApp);
                dispatch(selectAppAction({ app: new App(favApp.app) }));

                if (prevApp !== undefined) {
                  snackbarService.custom((key) => (
                    <KBarSnackBar
                      key={key}
                      closeKey={key}
                      action={rollBackPrevApp}
                      buttonText='Switch back'
                      title={`App was switched to ${_.capitalize(retrievedApp.name)}`}
                    />
                  ));

                  return rollBackPrevApp;
                }

                snackbarService.custom((key) => (
                  <KBarSnackBar
                    key={key}
                    closeKey={key}
                    title={`Application selected: ${_.capitalize(retrievedApp.name)}`}
                  />
                ));
              },
              (err) => {
                if (err.code === GenericFailureCode.EntityNotFound) {
                  deleteAppFromKbar(new App(favApp.app));
                }
                throwGenericFailure(err);
              }
            );
          })
          .catch((err: Error) => {
            handlerFailure(err);
          });
      },
    }));
    appsList.push(basicAppSelect, clearFavApps);

    return appsList;
  }, [parentSelectAppAction, favAppsSorted, clearFavApps, dispatch, app, prevApp, rollBackPrevApp, handlerFailure]);

  const actions: Action[] = [
    {
      id: 'focusToSearchAction',
      name: 'Search',
      keywords: 'search',
      section: KBAR_SECTION.SHORTCUTS,
      shortcut: ['s'],
      icon: <SearchIcon />,
      perform: () => {
        setTimeout(() => dispatch(kbarToolkit.actions.setSearchInputFocused(true)), 100);
      },
      subtitle: 'Focusing search field is available',
    },
    parentSelectAppAction,
    ...selectApp,
  ];

  useRegisterActions(actions, [favAppsRaw]);

  return null;
};
