import { FC, useEffect, useState } from 'react';

import { pluralize } from '/helpers';

import { ButtonStyled } from './styles';

interface INewNotificationsButtonProps {
  notificationsCount: number;
  onClick: () => void;
}

export const NewNotificationsButton: FC<INewNotificationsButtonProps> = ({ notificationsCount, onClick }) => {
  const [renderCount, setRenderCount] = useState<number>(notificationsCount || 0);

  useEffect(() => {
    if (notificationsCount !== 0) {
      setRenderCount(notificationsCount);
    }
  }, [notificationsCount]);

  return notificationsCount > 0 ? (
    <ButtonStyled onClick={onClick} color='primary' variant='contained' size='small'>
      SHOW {pluralize(renderCount, 'new notification')}
    </ButtonStyled>
  ) : null;
};
