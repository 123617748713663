import SvgIcon from '@mui/material/SvgIcon';
import {
  Apps as AppsIcon,
  AttachFile as AttachFileIcon,
  AttachMoney as AttachMoneyIcon,
  BeachAccess as BeachAccessIcon,
  BubbleChart as BubbleChartIcon,
  DateRange as DateRangeIcon,
  DeviceHub as DeviceHubIcon,
  Group as GroupIcon,
  GroupAdd as GroupAddIcon,
  Memory as MemoryIcon,
  PhonelinkSetup as PhonelinkSetupIcon,
  PowerSettingsNew as PowerSettingNewIcon,
  Public as PublicIcon,
  RecordVoiceOver as RecordVoiceOverIcon,
  Security as SecurityIcon,
  Send as SendIcon,
  Settings as SettingsIcon,
  SupervisorAccount as SupervisorAccountIcon,
  LocalOffer as LocalOfferIcon,
  Cloud as CloudIcon,
} from '@mui/icons-material';

import { Class as NotificationClass } from '@playq/octopus-notifications';

type SvgIconComponent = typeof SvgIcon;

export const getNotificationIcon = (notificationClass: NotificationClass): SvgIconComponent | undefined => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (notificationClass) {
    case NotificationClass.Auth:
      return PowerSettingNewIcon;
    case NotificationClass.Users:
      return SupervisorAccountIcon;
    case NotificationClass.Groups:
      return GroupIcon;
    case NotificationClass.Perms:
      return SecurityIcon;
    case NotificationClass.Apps:
      return AppsIcon;
    case NotificationClass.Integrations:
      return DeviceHubIcon;
    case NotificationClass.WebEvents:
      return SendIcon;
    case NotificationClass.Space:
      return BeachAccessIcon;
    case NotificationClass.Configuration:
      return SettingsIcon;
    case NotificationClass.EconomyCoupons:
    case NotificationClass.EconomyInventory:
    case NotificationClass.EconomyPackages:
      return AttachMoneyIcon;
    case NotificationClass.AnalyticsExperiments:
    case NotificationClass.AnalyticsEventsMapper:
    case NotificationClass.AnalyticsSegmentation:
    case NotificationClass.AnalyticsTraits:
      return BubbleChartIcon;
    case NotificationClass.SocialLeaderboards:
    case NotificationClass.SocialMatches:
    case NotificationClass.SocialTeams:
      return PublicIcon;
    case NotificationClass.Files:
      return AttachFileIcon;
    case NotificationClass.CreativeAssets:
      return GroupAddIcon;
    case NotificationClass.SupportLeaderboards:
      return RecordVoiceOverIcon;
    case NotificationClass.SupportPlayerLookup:
    case NotificationClass.SupportTeams:
    case NotificationClass.SupportMatchMonitoring:
      return RecordVoiceOverIcon;
    case NotificationClass.LiveOpsGameEvents:
    case NotificationClass.LiveOpsFlow:
    case NotificationClass.LiveOpsUserExplorer:
      return DateRangeIcon;
    case NotificationClass.MachineLearningSynapse:
      return MemoryIcon;
    case NotificationClass.Services:
      return PhonelinkSetupIcon;
    case NotificationClass.UsersTag:
      return LocalOfferIcon;
    case NotificationClass.CloudFunctions:
      return CloudIcon;
  }
};
