import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  speedDial: {
    position: 'absolute',
    right: theme.spacing(0.25),
    bottom: theme.spacing(1.5),
  },
  icon: {
    userSelect: 'none',
    fontSize: theme.typography.pxToRem(20),
    width: '1em',
    height: '1em',
    overflow: 'hidden',
    display: 'inline-block',
    flexShrink: 0,
  },
  dialogContent: {
    minWidth: '500px',
  },
  textField: {
    marginBottom: '15px',
  },
  errorText: {
    position: 'absolute',
    bottom: '-17px',
    margin: 0,
  },
  speedDialAction: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  submitButton: {
    minWidth: 100,
  },
}));
