import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ThemeMode } from '/common/models';
import { ThemeProvider } from '/common';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: 'inherit',
  },
  selected: {
    backgroundColor: `${theme.palette.background.paper} !important`,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    color: ThemeProvider.getAppropriateStyles(
      theme.palette.mode as ThemeMode,
      theme.palette.primary.main,
      theme.palette.text.disabled
    ),
    '&:hover': {
      backgroundColor: `${theme.palette.action.selected} !important`,
    },
  },
}));
