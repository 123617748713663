import React from 'react';
import { Card, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { StyledSnackBarButton, StyledSnackBarContent } from '/common/Kbar/KBarSnackBar/styles';

import { IKBarSnackBar } from './types';

export const KBarSnackBar = React.forwardRef<HTMLDivElement, IKBarSnackBar>((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  const { closeKey, title, buttonText, action } = props;

  const handleClick = () => {
    if (action === undefined) {
      return;
    }
    action();
    closeSnackbar(closeKey);
  };

  return (
    <Card ref={ref}>
      <StyledSnackBarContent>
        <Typography sx={{ padding: '0' }} variant='subtitle1'>
          {title}
        </Typography>
        {buttonText && (
          <StyledSnackBarButton onClick={handleClick} color={'secondary'} variant={'text'}>
            {buttonText}
          </StyledSnackBarButton>
        )}
      </StyledSnackBarContent>
    </Card>
  );
});
