import { NavigationActions } from '/common/Kbar/actions/NavigationAction';
import { ModuleAction } from '/common/Kbar/actions/ModuleAction';

import { ShortcutsAction } from './ShortcutsAction';
import { ThemeActions } from './ThemeAction';

export const Actions = () => {
  const isDev = __CI_BRANCH__ === 'develop' || !__CI_BRANCH__;

  return (
    <>
      <ShortcutsAction />
      <ThemeActions />
      <ModuleAction />
      {isDev && <NavigationActions />}
    </>
  );
};
