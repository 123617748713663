import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Class as NotificationClass, NotificationsFilterField } from '@playq/octopus-notifications';
import { OptionsFilter } from '@playq/octopus-common';

import { appToolkit, currentModuleToolkit } from '/store/toolkits';

import { timelineModules } from '../TimelineModules';

import { TimelineInfo, TimelineMode, UseTimelineParams } from './types';
import {
  checkIfAppModule,
  checkIfCanBeModuleClass,
  checkIfCanHasTimeline,
  checkIfIsAppTimeline,
  getModuleRouteName,
} from './helpers';
import { classesRouteNames } from './consts';

export const useTimeline = ({
  pathname,
  setTimelineState,
  mode = TimelineMode.SideBar,
  initialQuery = {},
}: UseTimelineParams): TimelineInfo => {
  const { linkToTheLastModulePage } = useSelector(currentModuleToolkit.selectors.currentModule);
  const app = useSelector(appToolkit.selectors.app);
  const moduleRouteName = useMemo(
    () => getModuleRouteName(linkToTheLastModulePage ?? pathname),
    [linkToTheLastModulePage, pathname]
  );

  const isAppModule = checkIfAppModule(pathname, app?.routeName);

  const moduleClass = useMemo(() => {
    if (isAppModule) {
      return NotificationClass.Apps;
    }
    return moduleRouteName && checkIfCanBeModuleClass(pathname) ? classesRouteNames[moduleRouteName] : undefined;
  }, [isAppModule, pathname, moduleRouteName]);

  const { timelineModule, hasTimelineModule } = useMemo(
    () =>
      Object.values(timelineModules).reduce(
        (acc: Pick<TimelineInfo, 'timelineModule' | 'hasTimelineModule'>, m) => {
          if (!app) {
            return acc;
          }

          const result = m(app, pathname);
          const fieldToCheck = mode === TimelineMode.Page ? result.showTimelinePage : result.showTimelineSidebar;
          if (fieldToCheck) {
            acc.timelineModule = result;
          }

          if (
            !!moduleRouteName &&
            (result.rootPath.includes(moduleRouteName) || isAppModule) &&
            checkIfCanHasTimeline(pathname)
          ) {
            acc.hasTimelineModule = true;
          }
          return acc;
        },
        {
          timelineModule: undefined,
          hasTimelineModule: false,
        }
      ),
    [app, isAppModule, mode, moduleRouteName, pathname]
  );

  const isAppTimeline = checkIfIsAppTimeline(pathname);

  useEffect(function updateTimelineStateWithInitialQuery() {
    setTimelineState((prevState) => ({
      ...prevState,
      query: {
        ...prevState.query,
        ...initialQuery,
      },
    }));
    // shoule be done only one time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    function setFilterByModuleClassIfNoTimelineModule() {
      if (!isAppTimeline && moduleClass !== undefined && !hasTimelineModule) {
        setTimelineState((prevState) => ({
          ...prevState,
          query: {
            ...prevState.query,
            filterBy: {
              [NotificationsFilterField.Class]: new OptionsFilter({
                options: [moduleClass],
              }),
            },
          },
        }));
      }
    },
    [hasTimelineModule, isAppTimeline, moduleClass, setTimelineState, timelineModule]
  );

  return {
    moduleRouteName,
    moduleClass,
    timelineModule,
    hasTimelineModule,
  };
};
