import {
  Apps as AppsIcon,
  AttachFile as AttachFileIcon,
  AttachMoney as AttachMoneyIcon,
  BubbleChart as BubbleChartIcon,
  DateRange as DateRangeIcon,
  GroupAdd as GroupAddIcon,
  InsertChart as InsertChartIcon,
  Memory as MemoryIcon,
  PhoneIphone as MobileIcon,
  PhonelinkSetup as PhonelinkSetupIcon,
  Public as PublicIcon,
  RecordVoiceOver as RecordVoiceOverIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';

import { AccessLevel, PermModule, Perms, UserRole } from '@playq/octopus2-auth';

import { appsPath } from '/constants';
import { SpaceLessModules } from '/constants/SpaceLessModules';

export interface INavItem {
  caption: string;
  disabled?: boolean;
  children?: INavItem[];
  icon?: JSX.Element;
  route?: string;
  hidden?: boolean;
  module?: PermModule;
}

const generalNavConfig: INavItem[] = [
  {
    caption: 'Apps',
    icon: <AppsIcon data-testid='icon-apps' />,
    route: appsPath,
    module: PermModule.None,
  },
  {
    caption: 'Files',
    icon: <AttachFileIcon data-testid='icon-file' />,
    route: '/files',
    module: PermModule.None,
  },
];

export const statusMonitorNavConfig = {
  caption: 'Status Monitor',
  route: `/status`,
  icon: <InsertChartIcon data-testid='status-monitor' />,
  module: PermModule.None,
};

const appNavConfig = (appName: string, hidden?: boolean): INavItem[] => {
  return [
    {
      caption: 'Device',
      icon: <MobileIcon />,
      route: `/apps/${appName}/device`,
      hidden: hidden,
    },
    {
      caption: 'Configuration',
      icon: <SettingsIcon data-testid='icon-settings' />,
      route: `/apps/${appName}/configuration`,
      module: PermModule.Configuration,
    },
    {
      caption: 'Economy',
      children: [
        {
          caption: 'Inventory',
          route: `/apps/${appName}/economy/${SpaceLessModules.Inventory}`,
          module: PermModule.EconomyInventory,
        },
        {
          caption: 'Packages',
          route: `/apps/${appName}/economy/${SpaceLessModules.Packages}`,
          module: PermModule.EconomyPackages,
        },
        {
          caption: 'Coupons',
          route: `/apps/${appName}/economy/${SpaceLessModules.Coupons}/mass`,
          module: PermModule.EconomyCoupons,
        },
      ],
      icon: <AttachMoneyIcon data-testid='icon-money' />,
    },
    {
      caption: 'Social',
      children: [
        {
          caption: 'Leaderboards',
          route: `/apps/${appName}/social/leaderboards`,
          module: PermModule.SocialLeaderboards,
        },
        {
          caption: 'Matches',
          route: `/apps/${appName}/social/${SpaceLessModules.Matches}`,
          module: PermModule.SocialMatches,
        },
        {
          caption: 'Teams',
          route: `/apps/${appName}/social/${SpaceLessModules.Teams}`,
          module: PermModule.SocialTeams,
        },
      ],
      icon: <PublicIcon data-testid='icon-public' />,
    },
    {
      caption: 'Analytics',
      children: [
        {
          caption: 'Traits',
          route: `/apps/${appName}/analytics/traits`,
          module: PermModule.AnalyticsTraits,
        },
        {
          caption: 'Segmentation',
          route: `/apps/${appName}/analytics/segmentation`,
          module: PermModule.AnalyticsSegmentation,
        },
        {
          caption: 'Events Mapper',
          route: `/apps/${appName}/analytics/events`,
          module: PermModule.AnalyticsEventsMapper,
        },
        {
          caption: 'Experiments',
          route: `/apps/${appName}/analytics/experiments`,
          module: PermModule.AnalyticsExperiments,
        },
        /*
        {
          caption: 'KPIs',
          route: `/apps/${appName}/analytics/kpis`,
          module: PermModule.KPIFinancial,
        },
        */
        {
          caption: 'Blueprints',
          route: `/apps/${appName}/analytics/blueprints`,
        },
        {
          caption: 'Dashboards',
          route: `/apps/${appName}/analytics/dashboards`,
        },
      ],
      icon: <BubbleChartIcon data-testid='icon-bubble-chart' />,
    },
    {
      caption: 'Live Ops',
      children: [
        {
          caption: 'Game Events',
          route: `/apps/${appName}/liveops/events`,
          module: PermModule.LiveOpsGameEvents,
        },
        /*
        {
          caption: 'User Explorer',
          route: `/apps/${appName}/liveops/explorer`,
          module: PermModule.LiveOpsUserExplorer,
          disabled: true,
        },
 */
        {
          caption: 'Flows',
          route: `/apps/${appName}/liveops/flows`,
          module: PermModule.LiveOpsFlow,
        },
      ],
      icon: <DateRangeIcon data-testid='icon-date-range' />,
    },
    {
      caption: 'Machine Learning',
      children: [
        {
          caption: 'Synapse',
          route: `/apps/${appName}/ml/${SpaceLessModules.Synapse}/problems`,
          module: PermModule.MachineLearningSynapse,
        },
      ],
      icon: <MemoryIcon data-testid='icon-memory' />,
    },
    {
      caption: 'User Acquisition',
      children: [
        {
          caption: 'Creative Assets',
          route: `/apps/${appName}/ua/assets`,
          module: PermModule.UserAcquisitionCreativeAssets,
        },
        {
          caption: 'Creative Copy',
          route: `/apps/${appName}/ua/copy`,
          module: PermModule.UserAcquisitionCreativeCopy,
        },
      ],
      icon: <GroupAddIcon data-testid='icon-group-add' />,
    },
    {
      caption: 'Support',
      children: [
        {
          caption: 'Leaderboards',
          route: `/apps/${appName}/support/${SpaceLessModules.Leaderboards}`,
          module: PermModule.SupportLeaderboards,
        },
        {
          caption: 'Player Lookup',
          route: `/apps/${appName}/support/playerlookup`,
          module: PermModule.SupportPlayerLookup,
        },
        {
          caption: 'Teams Lookup',
          route: `/apps/${appName}/support/teamslookup`,
          module: PermModule.SupportTeams,
        },
        {
          caption: 'Beetle Lookup',
          route: `/apps/${appName}/support/beetle`,
          module: PermModule.AudienceSupport,
        },
      ],
      icon: <RecordVoiceOverIcon data-testid='icon-record-voice' />,
      disabled: false,
    },
    {
      caption: 'Services',
      icon: <PhonelinkSetupIcon data-testid='phone-setup-icon' />,
      children: [
        {
          caption: 'Config',
          route: `/apps/${appName}/services/config`,
          module: PermModule.Services,
        },
        {
          caption: 'Cloud Functions',
          route: `/apps/${appName}/services/cloud-functions`,
          module: PermModule.CloudFunctions,
        },
        {
          caption: 'Scripts',
          route: `/apps/${appName}/services/scripts`,
          module: PermModule.Scripts,
        },
      ],
    },
  ];
};

function hasNavAccess(nav: INavItem, access: Perms['access']): boolean {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (nav.route && nav.module) {
    return (
      access[nav.module] === AccessLevel.Read ||
      access[nav.module] === AccessLevel.Write ||
      access[nav.module] === AccessLevel.WriteLive
    );
  }

  if (nav.children) {
    const accessibleChildren = nav.children.filter((child: INavItem) => hasNavAccess(child, access));
    if (accessibleChildren.length) {
      nav.children = accessibleChildren;
      return true;
    }
  }

  return false;
}

export interface INavConfigProps {
  appName?: string;
  role: UserRole;
  access: Perms['access'];
  hidden?: boolean;
}

export const createNavConfig = ({ appName, role, access, hidden }: INavConfigProps) => {
  let navConfig = generalNavConfig;

  if (appName) {
    const appConfig = appNavConfig(appName, hidden);
    switch (role) {
      case UserRole.SuperAdmin:
      case UserRole.Admin:
        navConfig = navConfig.concat(appConfig);
        break;
      case UserRole.Member:
        navConfig = navConfig.concat(
          appConfig.filter((nav: INavItem) => (nav.caption === 'Device' ? nav : hasNavAccess(nav, access)))
        );
        break;
      default:
        break;
    }
  }

  return navConfig;
};
