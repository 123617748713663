import { styled } from '@mui/material';
import { KBarAnimator, KBarPositioner, KBarSearch } from 'kbar';

import { IActiveResult } from '/common/Kbar/types';
import { ThemeMode } from '/common/models';
import { ThemeProvider } from '/common';

export const StyledKBarPositioner = styled(KBarPositioner)`
  z-index: 1400;
`;
export const StyledKBarAnimator = styled(KBarAnimator)`
  max-width: 600px;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;

  ${({ theme }) => `
    color: ${theme.palette.text.primary};
    background-color: ${theme.palette.background.paper};
    box-shadow: ${ThemeProvider.getAppropriateStyles(
      theme.palette.mode as ThemeMode,
      '0 6px 20px rgb(0 0 0 / 20%)',
      '0 6px 20px rgb(0 0 0 / 50%)'
    )}
    
    
  `}
`;

export const StyledKBarSearch = styled(KBarSearch)`
  padding: 12px 16px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  border: none;

  ${({ theme }) => `
    color: ${theme.palette.text.primary};
    background-color: ${theme.palette.background.paper};
  `}
`;

export const StyledKBarGroupResult = styled('div')`
  padding: 8px 16px;
  font-size: 0.625rem;
  text-transform: uppercase;
  opacity: 0.5;

  ${({ theme }) => `
    color: ${theme.palette.text.primary};
    background-color: ${theme.palette.background.paper};
  `}
`;

export const KBarResultItemWrapper = styled('div')<IActiveResult>`
  padding: 12px 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  ${({ theme, active }) => `
     background: ${active ? theme.palette.background.default : 'transparent'};
     border-left: 2px solid ${active ? theme.palette.primary.main : 'transparent'};
     color: ${theme.palette.text.primary};
  `}
`;

export const KBarResultItemNameSection = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 0.875rem;
`;

export const KBarResultItemName = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const KBarResultGroupName = styled('span')`
  opacity: 0.5;
  margin-right: 8px;
`;

export const KBarResultGroupSymbol = styled('span')`
  margin-right: 8px;
`;

export const KBarResultSubtitle = styled('span')`
  font-size: 0.75rem;
  opacity: 0.75;
`;

export const KBarResultIcon = styled('div')`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const KBarResultShortcutWrapper = styled('div')`
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
`;

export const KBarKBDStyled = styled('kbd')`
  padding: 4px 6px;
  border-radius: 4px;
  font-size: 0.875rem;
  font-family: monospace;

  ${({ theme }) => `
     background: ${ThemeProvider.getAppropriateStyles(theme.palette.mode as ThemeMode, '#00000033', '#ffffff33')}
  `}
`;
