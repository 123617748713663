import { Dialog, DialogContent, DialogTitle, styled, Typography } from '@mui/material';
import { Comment as CommentIcon } from '@mui/icons-material';

export const CommentIconStyled = styled(CommentIcon)`
  margin-bottom: -4px;
`;

export const DialogStyled = styled(Dialog)`
  .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 24px);
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

export const DialogTitleStyled = styled(DialogTitle)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: ${(props) => props.theme.spacing(1)};
  padding-bottom: ${(props) => props.theme.spacing(1)};
`;

export const DialogContentStyled = styled(DialogContent)`
  min-width: 500px;
  padding-top: ${(props) => props.theme.spacing(3)};
  padding-bottom: ${(props) => props.theme.spacing(3)};
`;

export const Form = styled('form')`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing(2)};
  padding: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(3)};
`;

export const Username = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

export const Message = styled(Typography)`
  word-break: break-all;
`;
