import { FC } from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

import { tooltipEnterDelay } from '/constants';

export const ToolTip: FC<TooltipProps> = ({ title, children, ...props }: TooltipProps) => (
  <Tooltip title={title} arrow={true} enterDelay={tooltipEnterDelay} placement='top' {...props}>
    {children}
  </Tooltip>
);
