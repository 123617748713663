import { useEffect, useMemo, useState } from 'react';
import { useQueryClient, QueryKey } from '@tanstack/react-query';

import { OffsetLimit, GenericFailure } from '@playq/services-shared';
import { Either } from '@playq/irt';

import { updateQueryKeysIterator } from '/helpers';

import { unwrapEither } from './unwrapEither';

export type UseQueryPrefetchParams<Args extends unknown[], Data> = {
  keys: QueryKey;
  enabled?: boolean | undefined;
  total?: number | undefined;
  hasMore?: boolean;
  args: Args;
  serviceMethod: (...args: Args) => Promise<Either<GenericFailure, Data>>;
};

const checkIfHasMore = (iterator: OffsetLimit | undefined, total: number | undefined) =>
  iterator !== undefined && total !== undefined && iterator.offset + iterator.limit < total;

export const useQueryPrefetch = <Args extends unknown[], Data>({
  keys,
  enabled,
  total,
  args,
  hasMore: propsHasMore,
  serviceMethod,
}: UseQueryPrefetchParams<Args, Data>) => {
  const queryClient = useQueryClient();

  const iterator = useMemo(() => args.find((arg) => arg instanceof OffsetLimit) as OffsetLimit | undefined, [args]);

  const nextIterator = useMemo(() => {
    if (iterator instanceof OffsetLimit) {
      return new OffsetLimit({
        limit: iterator.limit,
        offset: iterator.limit + iterator.offset,
      });
    }
  }, [iterator]);

  const nextKeys = useMemo(
    () => (nextIterator === undefined ? undefined : updateQueryKeysIterator(keys as unknown[])),
    [keys, nextIterator]
  );
  const hasMore = useMemo(() => propsHasMore ?? checkIfHasMore(iterator, total), [propsHasMore, iterator, total]);

  const [preFetchData, setPreFetchData] = useState<Data | undefined>();

  const nextArgs = useMemo(
    () => (nextIterator === undefined ? undefined : updateQueryKeysIterator(args)),
    [args, nextIterator]
  );

  useEffect(() => {
    if (enabled === false || !nextKeys || !nextArgs || !hasMore) {
      return;
    }

    const cachedData = queryClient.getQueryData(keys);
    if (cachedData === undefined) {
      return;
    }

    const cachedNextData = queryClient.getQueryData(nextKeys);

    if (cachedNextData === undefined) {
      queryClient
        .prefetchQuery(nextKeys, () => unwrapEither(serviceMethod(...(nextArgs as Args))))
        .then(() => {
          if (propsHasMore) {
            setPreFetchData(queryClient.getQueryData(nextKeys));
          }
        });
    }
  }, [enabled, nextKeys, propsHasMore, keys, queryClient, nextArgs, hasMore, serviceMethod, preFetchData]);

  return { nextIterator, nextKeys, preFetchData, setPreFetchData };
};
