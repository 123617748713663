import { Class as NotificationClass } from '@playq/octopus-notifications';

import { services2 } from '/api/services2';
import { freezeDeepAsync } from '/helpers/freezeDeepAsync/freezeDeepAsync';

export const APP_TIMELINE_ROUTE_NAME = 'app-timeline';

export const notificationsService = {
  getFullClassName: () => services2.notificationsTimelineService.getFullClassName(),
  queryNotifications: services2.notificationsTimelineService.queryTimeline.bind(services2.notificationsTimelineService),
};

export const classesRouteNames: Record<string, NotificationClass> = {
  integrations: NotificationClass.Integrations,
  users: NotificationClass.Users,
  groups: NotificationClass.Groups,
  permissions: NotificationClass.Perms,
  credentials: NotificationClass.Credentials,
  webevents: NotificationClass.WebEvents,
  'tags-manager': NotificationClass.UsersTag,
  files: NotificationClass.Files,
  assets: NotificationClass.CreativeAssets,
  traits: NotificationClass.AnalyticsTraits,
  events: NotificationClass.AnalyticsEventsMapper,
  blueprints: NotificationClass.BlueprintEntites,
  problems: NotificationClass.MachineLearningSynapse,
  config: NotificationClass.Services,
  playerlookup: NotificationClass.SupportPlayerLookup,
  teamslookup: NotificationClass.SupportTeams,
  support_requests: NotificationClass.Audiences,
  issues: NotificationClass.Audiences,
  beetle: NotificationClass.Audiences,
  apps: NotificationClass.Apps,
  configuration: NotificationClass.Configuration,
  inventory: NotificationClass.EconomyInventory,
  packages: NotificationClass.EconomyPackages,
  coupons: NotificationClass.EconomyCoupons,
  leaderboards: NotificationClass.SupportLeaderboards,
  matches: NotificationClass.SocialMatches,
  teams: NotificationClass.SocialTeams,
  segmentation: NotificationClass.AnalyticsSegmentation,
  experiments: NotificationClass.AnalyticsExperiments,
  flows: NotificationClass.LiveOpsFlow,
  'cloud-functions': NotificationClass.CloudFunctions,
  scripts: NotificationClass.Scripts,
};

(async () => {
  await freezeDeepAsync(classesRouteNames);
})();
