import { matchPath } from 'react-router-dom';

import { AppTeamTemplateId } from '@playq/services-shared';
import { FlowID } from '@playq/octopus-common';
import { ExperimentID } from '@playq/octopus2-analytics';
import { CouponID, CouponTemplateID, ItemID, PackageID } from '@playq/services-bookkeeper';
import { AppLadderId } from '@playq/services-clerk';
import { MatchConfigID } from '@playq/services-rush';
import { GameEventID } from '@playq/octopus2-liveops';
import { CloudFunctionID } from '@playq/octopus2-cloud';
import { ScriptID } from '@playq/octopus2-scripts';
import {
  Class,
  NotificationWithCloudFunction,
  NotificationWithCoupon,
  NotificationWithExperiment,
  NotificationWithFlow,
  NotificationWithGameEvent,
  NotificationWithItem,
  NotificationWithLeaderboard,
  NotificationWithMatch,
  NotificationWithPackage,
  NotificationWithTeamTemplate,
  NotificationWithScript,
} from '@playq/octopus-notifications';

import { services2 } from '/api/services2';
import { relativeCreateURL } from '/constants';

import { TimelineModule, TimelineModules } from './types';

const checkEntityId = (id?: string): boolean => id !== 'timeline';
const isCreateMode = (id?: string): boolean => id === relativeCreateURL;

// TODO: add new adminCredentialsManagement
// @ts-expect-error Property 'adminCredentialsManagement' is missing
export const timelineModules: Record<TimelineModules, TimelineModule> = {
  apps: (_app, path) => ({
    rootPath: '/apps/manage',
    service: {
      queryNotifications: (_appID, ...args) => services2.appsService.queryNotifications(...args),
      getFullClassName: () => services2.appsService.getFullClassName(),
    },
    showTimelineSidebar: path === '/apps' || matchPath(path, { path: `/apps/manage/:id` }) !== null,
    showTimelinePage: path === '/apps/timeline',
    isSingle: false,
    pageTitle: 'Apps',
    matchNotification: (n) => n.class_ === Class.Apps,
  }),
  appConfig: (_app, path) => {
    const rootPath = '/apps/:app/configuration';

    return {
      rootPath,
      service: {
        queryNotifications: (...args) => services2.appConfigService.queryNotifications(...args),
        getFullClassName: () => services2.appConfigService.getFullClassName(),
      },
      showTimelineSidebar: matchPath(path, { path: `${rootPath}/:id?` }) !== null,
      showTimelinePage: matchPath(path, { path: `${rootPath}/:id?/timeline` }) !== null,
      isSingle: true,
      pageTitle: 'App Config',
      matchNotification: (n) => n.class_ === Class.Configuration,
    };
  },
  appsInventory: (app, path) => {
    const rootPath = '/apps/:app/economy/inventory';

    const match = matchPath<{ id: string }>(path, {
      path: `${rootPath}/:app/:id/(timeline)?`,
    });
    const id = match?.params.id;
    const isvalidId = checkEntityId(id);
    let inventoryID: ItemID | undefined;
    if (id !== undefined && isvalidId) {
      inventoryID = new ItemID();
      inventoryID.id = id;
      inventoryID.app = app.fingerprintID.app;
    }

    return {
      rootPath,
      service: {
        queryNotifications: (appID, ...args) =>
          services2.appsInventoryService.queryNotifications(appID, inventoryID, ...args),
        getAnnotations:
          inventoryID !== undefined
            ? () => services2.appsInventoryService.getAnnotations(app.id, inventoryID as ItemID)
            : undefined,
        addAnnotation:
          inventoryID !== undefined
            ? (message) => services2.appsInventoryService.addAnnotation(app.id, inventoryID as ItemID, message)
            : undefined,
        getFullClassName: () => services2.appsInventoryService.getFullClassName() + (id ? `#${id}` : ``),
      },
      showTimelineSidebar: isvalidId && matchPath(path, { path: `${rootPath}/:app?/:id?` }) !== null,
      showTimelinePage:
        id !== relativeCreateURL && matchPath(path, { path: `${rootPath}/:app?/:id?/timeline` }) !== null,
      isSingle: id !== undefined && isvalidId,
      pageTitle: id !== undefined && id !== 'timeline' ? 'Inventory' : 'Inventories',
      matchNotification: (n) => {
        if (n.class_ !== Class.EconomyInventory) {
          return false;
        }
        if (!(id !== undefined && isvalidId)) {
          return true;
        }
        return id === (n as NotificationWithItem).itemID;
      },
    };
  },
  appsPackages: (app, path) => {
    const rootPath = '/apps/:app/economy/packages';

    const match = matchPath<{ id: string }>(path, {
      path: `${rootPath}/:id/(timeline)?`,
    });
    const id = match?.params.id;
    const isvalidId = checkEntityId(id);
    const isNewPackage = isCreateMode(id);
    let packageID: PackageID | undefined;
    if (id !== undefined && isvalidId && !isNewPackage) {
      packageID = new PackageID();
      packageID.id = id;
      packageID.app = app.fingerprintID.app;
    }

    return {
      rootPath,
      service: {
        queryNotifications: (appID, ...args) =>
          services2.appsPackagesService.queryNotifications(appID, packageID, ...args),
        getAnnotations:
          packageID !== undefined
            ? () => services2.appsPackagesService.getAnnotations(app.id, packageID as PackageID)
            : undefined,
        addAnnotation:
          packageID !== undefined
            ? (message) => services2.appsPackagesService.addAnnotation(app.id, packageID as PackageID, message)
            : undefined,
        getFullClassName: () => services2.appsPackagesService.getFullClassName() + (id ? `#${id}` : ``),
      },
      showTimelineSidebar: isvalidId && matchPath(path, { path: `${rootPath}/:id?` }) !== null,
      showTimelinePage: id !== relativeCreateURL && matchPath(path, { path: `${rootPath}/:id?/timeline` }) !== null,
      isSingle: id !== undefined && isvalidId,
      pageTitle: id !== undefined && isvalidId ? 'Package' : 'Packages',
      matchNotification: (n) => {
        if (n.class_ !== Class.EconomyPackages) {
          return false;
        }
        if (!(id !== undefined && isvalidId)) {
          return true;
        }
        return id === (n as NotificationWithPackage).packageID;
      },
    };
  },
  massCoupons: (app, path) => {
    const rootPath = '/apps/:app/economy/coupons/mass';

    const match = matchPath<{ id: string }>(path, {
      path: `${rootPath}/:id/(timeline)?`,
    });
    const id = match?.params.id;
    const isvalidId = checkEntityId(id);
    const isNewCoupon = isCreateMode(id);
    let massCouponID: CouponID | undefined;
    if (id !== undefined && isvalidId && !isNewCoupon) {
      massCouponID = new CouponID();
      massCouponID.id = id;
      massCouponID.app = app.fingerprintID.app;
    }

    return {
      rootPath,
      service: {
        queryNotifications: (appID, ...args) =>
          services2.massCouponsService.queryNotifications(appID, massCouponID, ...args),
        getAnnotations:
          massCouponID !== undefined
            ? () => services2.massCouponsService.getAnnotations(app.id, massCouponID as CouponID)
            : undefined,
        addAnnotation:
          massCouponID !== undefined
            ? (message) => services2.massCouponsService.addAnnotation(app.id, massCouponID as CouponID, message)
            : undefined,
        getFullClassName: () => services2.massCouponsService.getFullClassName() + (id ? `#${id}` : ``),
      },
      showTimelineSidebar: isvalidId && matchPath(path, { path: `${rootPath}/:id?` }) !== null,
      showTimelinePage: id !== relativeCreateURL && matchPath(path, { path: `${rootPath}/:id?/timeline` }) !== null,
      isSingle: id !== undefined && isvalidId,
      pageTitle: id !== undefined && isvalidId ? 'Mass Coupon' : 'Mass Coupons',
      matchNotification: (n) => {
        if (n.class_ !== Class.EconomyCoupons) {
          return false;
        }
        if (!(id !== undefined && isvalidId)) {
          return true;
        }
        return id === (n as NotificationWithCoupon).couponID;
      },
    };
  },
  targetedCoupons: (app, path) => {
    const rootPath = '/apps/:app/economy/coupons/targeted';

    const match = matchPath<{ id: string }>(path, {
      path: `${rootPath}/:id/(timeline)?`,
    });
    const id = match?.params.id;
    const isvalidId = checkEntityId(id);
    const isNewCoupon = isCreateMode(id);
    let targetedCouponID: CouponTemplateID | undefined;
    if (id !== undefined && isvalidId && !isNewCoupon) {
      targetedCouponID = new CouponTemplateID();
      targetedCouponID.id = id;
      targetedCouponID.app = app.fingerprintID.app;
    }

    return {
      rootPath,
      service: {
        queryNotifications: (appID, ...args) =>
          services2.targetedCouponsService.queryNotifications(appID, targetedCouponID, ...args),
        getAnnotations:
          targetedCouponID !== undefined
            ? () => services2.targetedCouponsService.getAnnotations(app.id, targetedCouponID as CouponTemplateID)
            : undefined,
        addAnnotation:
          targetedCouponID !== undefined
            ? (message) =>
                services2.targetedCouponsService.addAnnotation(app.id, targetedCouponID as CouponTemplateID, message)
            : undefined,
        getFullClassName: () => services2.targetedCouponsService.getFullClassName() + (id ? `#${id}` : ``),
      },
      showTimelineSidebar: isvalidId && matchPath(path, { path: `${rootPath}/:id?` }) !== null,
      showTimelinePage: id !== relativeCreateURL && matchPath(path, { path: `${rootPath}/:id?/timeline` }) !== null,
      isSingle: id !== undefined && isvalidId,
      pageTitle: id !== undefined && isvalidId ? 'Targeted Coupon' : 'Targeted Coupons',
      matchNotification: (n) => {
        if (n.class_ !== Class.EconomyCoupons) {
          return false;
        }
        if (!(id !== undefined && isvalidId)) {
          return true;
        }
        return id === (n as NotificationWithCoupon).couponID;
      },
    };
  },
  lboards: (app, path) => {
    const rootPath = '/apps/:app/social/leaderboards';

    const match = matchPath<{ id: string }>(path, {
      path: `${rootPath}/:id/(timeline)?`,
    });
    const id = match?.params.id;
    const isvalidId = checkEntityId(id);
    const isNewLboard = isCreateMode(id);
    let leaderboardID: AppLadderId | undefined;
    if (id !== undefined && isvalidId && !isNewLboard) {
      leaderboardID = new AppLadderId();
      leaderboardID.ladderId = id;
      leaderboardID.appId = app.fingerprintID.app;
    }

    return {
      rootPath,
      service: {
        queryNotifications: (appID, ...args) =>
          services2.lboardsService.queryNotifications(appID, leaderboardID, ...args),
        getAnnotations:
          leaderboardID !== undefined
            ? () => services2.lboardsService.getAnnotations(app.id, leaderboardID as AppLadderId)
            : undefined,
        addAnnotation:
          leaderboardID !== undefined
            ? (message) => services2.lboardsService.addAnnotation(app.id, leaderboardID as AppLadderId, message)
            : undefined,
        getFullClassName: () => services2.lboardsService.getFullClassName() + (id ? `#${id}` : ``),
      },
      showTimelineSidebar: isvalidId && matchPath(path, { path: `${rootPath}/:id?` }) !== null,
      showTimelinePage: id !== relativeCreateURL && matchPath(path, { path: `${rootPath}/:id?/timeline` }) !== null,
      isSingle: id !== undefined && isvalidId,
      pageTitle: id !== undefined && isvalidId ? 'Leaderboard' : 'Leaderboards',
      matchNotification: (n) => {
        if (n.class_ !== Class.SocialLeaderboards) {
          return false;
        }
        if (!(id !== undefined && isvalidId)) {
          return true;
        }
        return id === (n as NotificationWithLeaderboard).leaderboardID;
      },
    };
  },
  matches: (app, path) => {
    const rootPath = '/apps/:app/social/matches';

    const match = matchPath<{ id: string }>(path, {
      path: `${rootPath}/:id/(timeline)?`,
    });
    const id = match?.params.id;
    const isvalidId = checkEntityId(id);
    const isNewMatch = isCreateMode(id);
    let matchID: MatchConfigID | undefined;
    if (id !== undefined && isvalidId && !isNewMatch) {
      matchID = new MatchConfigID();
      matchID.id = id;
      matchID.app = app.fingerprintID.app;
    }

    return {
      rootPath,
      service: {
        queryNotifications: (appID, ...args) => services2.matchesService.queryNotifications(appID, matchID, ...args),
        getAnnotations:
          matchID !== undefined
            ? () => services2.matchesService.getAnnotations(app.id, matchID as MatchConfigID)
            : undefined,
        addAnnotation:
          matchID !== undefined
            ? (message) => services2.matchesService.addAnnotation(app.id, matchID as MatchConfigID, message)
            : undefined,
        getFullClassName: () => services2.matchesService.getFullClassName() + (id ? `#${id}` : ``),
      },
      showTimelineSidebar: isvalidId && matchPath(path, { path: `${rootPath}/:id?` }) !== null,
      showTimelinePage: id !== relativeCreateURL && matchPath(path, { path: `${rootPath}/:id?/timeline` }) !== null,
      isSingle: id !== undefined && isvalidId,
      pageTitle: id !== undefined && isvalidId ? 'Leaderboard' : 'Leaderboards',
      matchNotification: (n) => {
        if (n.class_ !== Class.SocialMatches) {
          return false;
        }
        if (!(id !== undefined && isvalidId)) {
          return true;
        }
        return id === (n as NotificationWithMatch).matchID;
      },
    };
  },
  teams: (app, path) => {
    const rootPath = '/apps/:app/social/teams';

    const match = matchPath<{ id: string }>(path, {
      path: `${rootPath}/:id/(timeline)?`,
    });
    const id = match?.params.id;
    const isvalidId = checkEntityId(id);
    const isNewTeam = isCreateMode(id);
    let teamID: AppTeamTemplateId | undefined;
    if (id !== undefined && isvalidId && !isNewTeam) {
      teamID = new AppTeamTemplateId();
      teamID.id = id;
      teamID.app = app.fingerprintID.app;
    }

    return {
      rootPath,
      service: {
        queryNotifications: (appID, ...args) => services2.teamsService.queryNotifications(appID, teamID, ...args),
        getAnnotations:
          teamID !== undefined
            ? () => services2.teamsService.getAnnotations(app.id, teamID as AppTeamTemplateId)
            : undefined,
        addAnnotation:
          teamID !== undefined
            ? (message) => services2.teamsService.addAnnotation(app.id, teamID as AppTeamTemplateId, message)
            : undefined,
        getFullClassName: () => services2.teamsService.getFullClassName() + (id ? `#${id}` : ``),
      },
      showTimelineSidebar: isvalidId && matchPath(path, { path: `${rootPath}/:id?` }) !== null,
      showTimelinePage: id !== relativeCreateURL && matchPath(path, { path: `${rootPath}/:id?/timeline` }) !== null,
      isSingle: id !== undefined && isvalidId,
      pageTitle: id !== undefined && isvalidId ? 'Leaderboard' : 'Leaderboards',
      matchNotification: (n) => {
        if (n.class_ !== Class.SocialTeams) {
          return false;
        }
        if (!(id !== undefined && isvalidId)) {
          return true;
        }
        return id === (n as NotificationWithTeamTemplate).teamTemplateID;
      },
    };
  },
  appSegments: (_app, path) => ({
    rootPath: '/analytics/segmentation',
    service: {
      queryNotifications: (...args) => services2.appSegmentsService.queryNotifications(...args),
      getFullClassName: () => services2.appSegmentsService.getFullClassName(),
    },
    showTimelineSidebar: path.endsWith('/analytics/segmentation'),
    showTimelinePage: path.endsWith('/analytics/segmentation/timeline'),
    isSingle: true,
    pageTitle: 'Segmentation',
    matchNotification: (n) => n.class_ === Class.AnalyticsSegmentation,
  }),
  appExperiments: (app, path) => {
    const rootPath = '/apps/:app/analytics/experiments';

    const match = matchPath<{ id: string }>(path, {
      path: `${rootPath}/:id/(timeline)?`,
    });
    const id = match?.params.id;
    const isvalidId = checkEntityId(id);
    const isNewExperiment = isCreateMode(id);
    let experimentID: ExperimentID | undefined;
    if (id !== undefined && isvalidId && !isNewExperiment) {
      experimentID = new ExperimentID();
      experimentID.id = parseInt(id);
      experimentID.app = app.id.id;
    }

    return {
      rootPath,
      service: {
        queryNotifications: (appID, ...args) =>
          services2.appExperimentsService.queryNotifications(appID, experimentID, ...args),
        getAnnotations:
          experimentID !== undefined
            ? () => services2.appExperimentsService.getAnnotations(app.id, experimentID as ExperimentID)
            : undefined,
        addAnnotation:
          experimentID !== undefined
            ? (message) => services2.appExperimentsService.addAnnotation(app.id, experimentID as ExperimentID, message)
            : undefined,
        getFullClassName: () => services2.appExperimentsService.getFullClassName() + (id ? `#${id}` : ``),
      },
      showTimelineSidebar: isvalidId && matchPath(path, { path: `${rootPath}/:id?` }) !== null,
      showTimelinePage: id !== relativeCreateURL && matchPath(path, { path: `${rootPath}/:id?/timeline` }) !== null,
      isSingle: id !== undefined && isvalidId,
      pageTitle: id !== undefined && isvalidId ? 'Experiment' : 'Experiments',
      matchNotification: (n) => {
        if (n.class_ !== Class.AnalyticsExperiments) {
          return false;
        }
        if (!(id !== undefined && isvalidId)) {
          return true;
        }
        return parseInt(id) === (n as NotificationWithExperiment).experimentID;
      },
    };
  },
  appGameEvents: (app, path) => {
    const rootPath = '/apps/:app/liveops/events';

    const match = matchPath<{ id: string }>(path, {
      path: `${rootPath}/:id/(timeline)?`,
    });
    const id = match?.params.id;
    const isvalidId = checkEntityId(id);
    const isNewGE = isCreateMode(id);
    let gameEventID: GameEventID | undefined;
    if (id !== undefined && isvalidId && !isNewGE) {
      gameEventID = new GameEventID();
      gameEventID.id = parseInt(id);
      gameEventID.app = app.id.id;
    }

    return {
      rootPath,
      service: {
        queryNotifications: (appID, ...args) =>
          services2.appGameEventsService.queryNotifications(appID, gameEventID, ...args),
        getAnnotations:
          gameEventID !== undefined
            ? () => services2.appGameEventsService.getAnnotations(app.id, gameEventID as GameEventID)
            : undefined,
        addAnnotation:
          gameEventID !== undefined
            ? (message) => services2.appGameEventsService.addAnnotation(app.id, gameEventID as GameEventID, message)
            : undefined,
        getFullClassName: () => services2.appGameEventsService.getFullClassName() + (id ? `#${id}` : ``),
      },
      showTimelineSidebar: isvalidId && matchPath(path, { path: `${rootPath}/:id?` }) !== null,
      showTimelinePage: id !== relativeCreateURL && matchPath(path, { path: `${rootPath}/:id?/timeline` }) !== null,
      isSingle: id !== undefined && isvalidId,
      pageTitle: id !== undefined && isvalidId ? 'Game Event' : 'Game Events',
      matchNotification: (n) => {
        if (n.class_ !== Class.LiveOpsGameEvents) {
          return false;
        }
        if (!(id !== undefined && isvalidId)) {
          return true;
        }
        return parseInt(id) === (n as NotificationWithGameEvent).gameEventID;
      },
    };
  },
  flows: (app, path) => {
    const rootPath = '/apps/:app/liveops/flows';

    const match = matchPath<{ id: string }>(path, {
      path: `${rootPath}/:id/(timeline)?`,
    });
    const id = match?.params.id;
    const isvalidId = checkEntityId(id);
    const isNewFlow = isCreateMode(id);
    let flowID: FlowID | undefined;
    if (id !== undefined && isvalidId && !isNewFlow) {
      flowID = new FlowID();
      flowID.id = parseInt(id);
      flowID.app = app.id.id;
    }

    return {
      rootPath,
      service: {
        queryNotifications: (appID, ...args) => services2.flowsService.queryNotifications(appID, flowID, ...args),
        getAnnotations:
          flowID !== undefined ? () => services2.flowsService.getAnnotations(app.id, flowID as FlowID) : undefined,
        addAnnotation:
          flowID !== undefined
            ? (message) => services2.flowsService.addAnnotation(app.id, flowID as FlowID, message)
            : undefined,
        getFullClassName: () => services2.flowsService.getFullClassName() + (id ? `#${id}` : ``),
      },
      showTimelineSidebar: isvalidId && matchPath(path, { path: `${rootPath}/:id?` }) !== null,
      showTimelinePage: id !== relativeCreateURL && matchPath(path, { path: `${rootPath}/:id?/timeline` }) !== null,
      isSingle: id !== undefined && isvalidId,
      pageTitle: id !== undefined && isvalidId ? 'Flow' : 'Flows',
      matchNotification: (n) => {
        if (n.class_ !== Class.LiveOpsFlow) {
          return false;
        }
        if (!(id !== undefined && isvalidId)) {
          return true;
        }
        return parseInt(id) === (n as NotificationWithFlow).flowID;
      },
    };
  },
  cloudFunctions: (app, path) => {
    const rootPath = '/apps/:app/services/cloud-functions';

    const match = matchPath<{ id: string }>(path, {
      path: `${rootPath}/:id/(timeline)?`,
    });
    const id = match?.params.id;
    const isvalidId = checkEntityId(id);
    const isNewCloudFunction = isCreateMode(id);
    let cloudFunctionID: CloudFunctionID | undefined;
    if (id !== undefined && isvalidId && !isNewCloudFunction) {
      cloudFunctionID = new CloudFunctionID();
      cloudFunctionID.id = parseInt(id);
      cloudFunctionID.app = app.id.id;
    }

    return {
      rootPath,
      service: {
        queryNotifications: (appID, ...args) =>
          services2.cloudFunctionsService.queryNotifications(appID, cloudFunctionID, ...args),
        getAnnotations:
          cloudFunctionID !== undefined
            ? () => services2.cloudFunctionsService.getAnnotations(app.id, cloudFunctionID as CloudFunctionID)
            : undefined,
        addAnnotation:
          cloudFunctionID !== undefined
            ? (message) =>
                services2.cloudFunctionsService.addAnnotation(app.id, cloudFunctionID as CloudFunctionID, message)
            : undefined,
        getFullClassName: () => services2.cloudFunctionsService.getFullClassName() + (id ? `#${id}` : ``),
      },
      showTimelineSidebar: isvalidId && matchPath(path, { path: `${rootPath}/:id?` }) !== null,
      showTimelinePage: id !== relativeCreateURL && matchPath(path, { path: `${rootPath}/:id?/timeline` }) !== null,
      isSingle: id !== undefined && isvalidId,
      pageTitle: id !== undefined && isvalidId ? 'Cloud Function' : 'Cloud Functions',
      matchNotification: (n) => {
        if (n.class_ !== Class.CloudFunctions) {
          return false;
        }
        if (!(id !== undefined && isvalidId)) {
          return true;
        }
        return parseInt(id) === (n as NotificationWithCloudFunction).cloudFunctionID;
      },
    };
  },
  scripts: (app, path) => {
    const rootPath = '/apps/:app/services/scripts';

    const match = matchPath<{ id: string }>(path, {
      path: `${rootPath}/:id/(timeline)?`,
    });
    const id = match?.params.id;
    const isvalidId = checkEntityId(id);
    const isNewScript = isCreateMode(id);
    let cloudFunctionID: ScriptID | undefined;
    if (id !== undefined && isvalidId && !isNewScript) {
      cloudFunctionID = new ScriptID();
      cloudFunctionID.id = parseInt(id);
      cloudFunctionID.app = app.id.id;
    }

    return {
      rootPath,
      service: {
        queryNotifications: (appID, ...args) =>
          services2.scriptsService.queryNotifications(appID, cloudFunctionID, ...args),
        getAnnotations:
          cloudFunctionID !== undefined
            ? () => services2.scriptsService.getAnnotations(app.id, cloudFunctionID as ScriptID)
            : undefined,
        addAnnotation:
          cloudFunctionID !== undefined
            ? (message) => services2.scriptsService.addAnnotation(app.id, cloudFunctionID as ScriptID, message)
            : undefined,
        getFullClassName: () => services2.scriptsService.getFullClassName() + (id ? `#${id}` : ``),
      },
      showTimelineSidebar: isvalidId && matchPath(path, { path: `${rootPath}/:id?` }) !== null,
      showTimelinePage: id !== relativeCreateURL && matchPath(path, { path: `${rootPath}/:id?/timeline` }) !== null,
      isSingle: id !== undefined && isvalidId,
      pageTitle: id !== undefined && isvalidId ? 'Script' : 'Scripts',
      matchNotification: (n) => {
        if (n.class_ !== Class.Scripts) {
          return false;
        }
        if (!(id !== undefined && isvalidId)) {
          return true;
        }
        return parseInt(id) === (n as NotificationWithScript).scriptID;
      },
    };
  },
};
