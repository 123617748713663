import { appsPath } from '/constants';

import { INavItem } from './navConfig';

export const getItemPath = (item: INavItem, route: string, parentCaptions: string[] = []): string[] | undefined => {
  const newParentCaptions = [...parentCaptions, item.caption];

  if (
    item.route &&
    (item.route === route ||
      route.includes(`${appsPath}/manage`) ||
      (route.includes(item.route) && item.route !== appsPath))
  ) {
    return newParentCaptions;
  }

  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  if (!item.children || !item.children.length) {
    return;
  }

  for (const child of item.children) {
    const childPath = getItemPath(child, route, newParentCaptions);

    if (childPath) {
      return childPath;
    }
  }
};

export const getSelectedItems = ([item, ...items]: INavItem[], route: string): string[] | undefined => {
  const itemPath = getItemPath(item, route);

  if (itemPath) {
    return itemPath;
  }

  return items?.length ? getSelectedItems(items, route) : undefined;
};
