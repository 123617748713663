import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';

import { UserAnnotations, UserAnnotation } from '@playq/octopus-notifications';

import { useAddEntityAnnotations, useGetEntityAnnotations } from '/api/hooks/timeline';
import { authToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';

import { EntityAnnotationsProps } from './types';
import { Annotations } from './Annotations';
import { CommentIconStyled } from './styles';

export const EntityAnnotations: FC<EntityAnnotationsProps> = ({
  getAnnotations,
  addAnnotation,
  serviceKey,
  buttonSize,
}) => {
  const user = useSelector(authToolkit.selectors.user);

  const [open, setOpen] = useState(false);

  const { data, mutate } = useGetEntityAnnotations(getAnnotations, serviceKey);
  const { mutate: addAnnotations } = useAddEntityAnnotations(addAnnotation, {
    onMutate: (message) => {
      if (user) {
        const now = new Date();
        const userAnnotation = new UserAnnotation();
        userAnnotation.at = now;
        userAnnotation.atAsString = now.toISOString();
        userAnnotation.userName = user.name;
        userAnnotation.userID = user.id;
        userAnnotation.message = message;
        const userAnnotations = new UserAnnotations();
        userAnnotations.annotations = [userAnnotation, ...(data?.annotations || [])];
        mutate(userAnnotations);
      }

      return data;
    },
    onError: (error, _payload, captured) => {
      snackbarService.genericFailure(error);
      if (captured) {
        mutate(captured);
      }
    },
  });

  const annotations = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.annotations;
  }, [data]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (message: string) => {
    addAnnotations(message);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        endIcon={!!annotations.length && <CommentIconStyled fontSize={buttonSize} />}
        size={buttonSize}
      >
        {annotations.length || <CommentIconStyled fontSize={buttonSize} />}
      </Button>
      <Annotations annotations={annotations} onSubmit={handleSubmit} open={open} onClose={handleClose} />
    </>
  );
};
