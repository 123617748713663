import { FC, useMemo, useCallback, useState } from 'react';
import { CssBaseline } from '@mui/material';
import { useSelector } from 'react-redux';

import { Header } from '/common/Header';
import { NavSidebar } from '/common/NavSidebar';
import { RoutesList } from '/Router/RoutesList';
import { IRouteComponentProps } from '/Router/models';
import { Helper } from '/common/Helper';
import { AppTimeline } from '/common/AppTimeline';
import { ProgressIndicatorProvider } from '/common/ProgressIndicator';
import { uiToolkit } from '/store';

import { useStyles } from './styles';

export const AppPage: FC<IRouteComponentProps> = ({ routes = [], location }) => {
  const isNavSidebarPermanentlyOpen: boolean = useSelector(uiToolkit.selectors.navSidebarOpen);
  const [isNavSidebarOpen, setIsNavSidebarOpen] = useState(isNavSidebarPermanentlyOpen);
  const classes = useStyles({ isNavSidebarOpen });
  const routerList = useMemo(() => <RoutesList routes={routes} />, [routes]);

  const handleNavSidebarOpeningStatusChange = useCallback((isOpen: boolean) => {
    setIsNavSidebarOpen(isOpen);
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ProgressIndicatorProvider>
        <Header />
      </ProgressIndicatorProvider>
      <NavSidebar location={location} onOpeningStatusChange={handleNavSidebarOpeningStatusChange} />
      <div className={classes.content}>
        {routerList}
        <Helper />
      </div>
      <AppTimeline />
    </div>
  );
};
