import { KBarPortal } from 'kbar';

import { Actions } from '/common/Kbar/actions/Actions';
import { StyledKBarAnimator, StyledKBarPositioner, StyledKBarSearch } from '/common/Kbar/styles';
import { CommandBarResult } from '/common/Kbar/CommandBarResult';

export const CommandBar = () => (
  <KBarPortal data-testid={'kbar-portal'}>
    <StyledKBarPositioner data-testid={'kbar'}>
      <StyledKBarAnimator>
        <StyledKBarSearch data-testid={'kbar-search'} />
        <CommandBarResult data-testid={'kbar-result'} />
      </StyledKBarAnimator>
      <Actions />
    </StyledKBarPositioner>
  </KBarPortal>
);
