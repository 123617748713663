import { FC, SyntheticEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import {
  AccountCircle as AccountCircleIcon,
  Face as FaceIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
  VpnKeyRounded,
} from '@mui/icons-material';

import { useAppDispatch } from '/store';
import { logout } from '/store/toolkits/auth/actions';
import { keysManagerPath } from '/component/ApiKeysManager/ApiKeysManagerRoutes';

import { useStyles } from './styles';

const profileUrl = '/user-profile';

type ProfileProps = {
  isControlled?: boolean;
};

export const Profile: FC<ProfileProps> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>();

  const handleOpen = (event: SyntheticEvent) => {
    const target = event.currentTarget as HTMLButtonElement;
    setAnchorEl(target);
  };

  const handleClose = (event?: unknown) => {
    if ((event as SyntheticEvent & { metaKey?: boolean })?.metaKey) {
      return;
    }
    setAnchorEl(undefined);
  };

  const onLogOut = () => {
    handleClose();
    dispatch(logout());
  };

  return (
    <>
      <IconButton
        color='inherit'
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup='true'
        aria-label='Profile section'
        onClick={handleOpen}
        size='large'
      >
        <FaceIcon />
      </IconButton>
      <Menu id='simple-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <Link to={profileUrl} className={classes.link} onClick={handleClose}>
          <MenuItem>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText className={classes.item} primary='Profile' />
          </MenuItem>
        </Link>

        <Link to={keysManagerPath} className={classes.link} onClick={handleClose}>
          <MenuItem>
            <ListItemIcon>
              <VpnKeyRounded />
            </ListItemIcon>
            <ListItemText className={classes.item} primary='API Keys' />
          </MenuItem>
        </Link>

        <Divider />

        <MenuItem onClick={onLogOut}>
          <ListItemIcon>
            <PowerSettingsNewIcon />
          </ListItemIcon>
          <ListItemText className={classes.item} primary='Log out' />
        </MenuItem>
      </Menu>
    </>
  );
};
