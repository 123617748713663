import { Button, IconButton, styled } from '@mui/material';

const spaceLessColor = '#B0BEC5';

export const ActiveSpace = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isSpaceLessModule',
})<{ isSpaceLessModule: boolean }>`
  font-size: 15px;
  border: 2px dashed
    ${({ theme, isSpaceLessModule }) => (isSpaceLessModule ? spaceLessColor : theme.palette.primary.contrastText)};
  color: ${({ isSpaceLessModule }) => (isSpaceLessModule ? `${spaceLessColor}` : 'inherit')};
  text-transform: none;
  padding: 0 10px;
  align-self: center;
  margin: 0 5px;
  position: relative;
`;

export const SpacelessMark = styled('span')<{ activeSpace: boolean }>`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.warning.main};
  position: ${({ activeSpace }) => (activeSpace ? 'static' : 'absolute')};
  margin-right: ${({ activeSpace }) => (activeSpace ? '5px' : '0')};
  left: 7px;
  top: 10px;
`;

export const IconButtonStyled = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isSpaceLessModule',
})<{ isSpaceLessModule: boolean }>`
  color: ${({ isSpaceLessModule }) => (isSpaceLessModule ? `${spaceLessColor}` : 'inherit')};
  position: 'relative';
`;
