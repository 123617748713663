import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Button, Divider, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import { Brightness4 as BrightnessIcon, Menu as MenuIcon, Timeline as TimelineIcon } from '@mui/icons-material';

import { App } from '@playq/octopus2-apps';

import { IThemeModeContext, SyntheticEventWithMetaKey, ThemeMode } from '/common/models';
import { ThemeModeContext } from '/common/theme';
import { appToolkit, authToolkit, IUIState, kbarToolkit, uiToolkit } from '/store';
import { AppPicker } from '/component/Apps/AppsPicker';
import { AppIcon } from '/component/Apps/AppIcon';
import { ProgressIndicator, ProgressIndicatorContext } from '/common/ProgressIndicator';
import { OnlinePageUsers } from '/shared/OnlinePageUsers';
import { OnlineDevices } from '/shared/OnlineDevices';
import { InvokeAllScriptsButton } from '/component/Services/Scripts/InvokeAllScriptsButton';
import { navigateToURL } from '/helpers';

import { SpacePicker } from './SpacePicker';
import { Corporate } from './Corporate';
import { Profile } from './Profile';
import { headerHeight, LinkToHome, useStyles } from './styles';

const isControlled = false; // todo
const loggedUserName = 'Master Joda'; // todo
const controlledBy = 'Controller'; // todo

export const Header: FC = () => {
  const { currentThemeMode, updateThemeMode }: IThemeModeContext = useContext(ThemeModeContext);
  const isProgressIndicatorVisible = useContext<boolean>(ProgressIndicatorContext);

  const [appSelectOpen, setAppSelectOpen] = useState(false);

  const classes = useStyles();

  const isAuthorizationSet: boolean = useSelector(authToolkit.selectors.isServicesAuthorizationSet);
  const app: App | undefined = useSelector(appToolkit.selectors.app);
  const uiState: IUIState = useSelector(uiToolkit.selectors.state);
  const openSelectAppModal = useSelector(kbarToolkit.selectors.openSelectAppModal);

  const dispatch = useDispatch();
  const updateUIState = useCallback((newState: IUIState) => dispatch(uiToolkit.actions.update(newState)), [dispatch]);

  const handleAppSelectOpen = useCallback(
    (val: boolean) => () => {
      setAppSelectOpen(val);
      if (!val) {
        dispatch(kbarToolkit.actions.setOpenSelectAppModal(false));
      }
    },
    [dispatch]
  );

  useEffect(
    function handleSelectAppModalByKbar() {
      if (openSelectAppModal) {
        setAppSelectOpen(true);
      }
    },
    [dispatch, handleAppSelectOpen, openSelectAppModal]
  );

  const toggleNavSidebar = () => {
    updateUIState({
      ...uiState,
      navSidebarOpen: !uiState.navSidebarOpen,
    });
  };

  const toggleTimeline = () => {
    const timelineState = uiState.timeline;
    const newOpen = !timelineState.open;
    updateUIState({
      ...uiState,
      timeline: { ...timelineState, filterBy: {}, open: newOpen },
    });
  };

  const updateThemeModeHandler = () => {
    if (currentThemeMode === ThemeMode.Dark) {
      updateThemeMode(ThemeMode.Light);
    } else {
      updateThemeMode(ThemeMode.Dark);
    }
  };

  const homeURL = '/';

  const handleGoHome = (event: SyntheticEventWithMetaKey) => navigateToURL({ url: homeURL, event });

  return (
    <>
      <AppBar
        position='fixed'
        sx={[
          (theme) => ({
            height: headerHeight,
            zIndex: theme.zIndex.drawer + 1,
          }),
          isControlled && {
            background: 'black',
          },
        ]}
        enableColorOnDark={true}
      >
        <Toolbar className={classes.toolBar} variant='dense'>
          <div className={classes.appInfo}>
            <ProgressIndicator isVisible={isProgressIndicatorVisible} />
            <IconButton onClick={toggleNavSidebar} color='inherit' aria-label='Toggle nav sidebar' size='large'>
              <MenuIcon />
            </IconButton>
            <Stack spacing={1} direction='row' alignItems='center'>
              <Typography noWrap={true} className='title' variant='h6'>
                <LinkToHome onClick={handleGoHome} underline='none' href={homeURL}>
                  PlayQ Octopus
                </LinkToHome>
              </Typography>
              <OnlinePageUsers />
              <OnlineDevices />
            </Stack>
          </div>
          <nav className={classes.nav}>
            <Button
              onClick={handleAppSelectOpen(true)}
              startIcon={
                app ? (
                  <div className={classes.appIconWrapper}>
                    <AppIcon app={app} />
                  </div>
                ) : null
              }
              color='inherit'
              aria-label='Select App'
              data-testid='select-app'
            >
              <Typography className={classes.appName} noWrap={true} color='inherit'>
                {app ? app.name : 'Select App'}
              </Typography>
            </Button>
            <SpacePicker />
            <Divider className={classes.divider} orientation='vertical' />
            {app && (
              <>
                {isAuthorizationSet && <InvokeAllScriptsButton />}

                <IconButton onClick={toggleTimeline} color='inherit' aria-label='Toggle timeline' size='large'>
                  <TimelineIcon />
                </IconButton>
              </>
            )}
            <IconButton onClick={updateThemeModeHandler} color='inherit' aria-label='Select Theme' size='large'>
              <BrightnessIcon />
            </IconButton>
            {isControlled && (
              <span className={classes.controlled}>
                Logged in as {loggedUserName}. <br />
                Controlled by {controlledBy}.
              </span>
            )}
            <Corporate />
            <Profile isControlled={isControlled} />
          </nav>
        </Toolbar>
      </AppBar>
      {appSelectOpen && <AppPicker onClose={handleAppSelectOpen(false)} />}
    </>
  );
};
