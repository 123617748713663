import { APP_TIMELINE_ROUTE_NAME, classesRouteNames } from './consts';

export const getModuleRouteName = (pathname: string) => {
  const urlChunks = pathname.split('/');
  const chunkWithModuleIdx = urlChunks.findLastIndex(
    (chunk) => !!chunk && Object.keys(classesRouteNames).includes(chunk)
  );
  return urlChunks[chunkWithModuleIdx];
};

export const checkIfIsSupportModule = (pathname?: string) => pathname?.includes('/support/');

export const checkIfAppModule = (moduleRouteName: string | undefined, appRouteName: string | undefined) =>
  !!moduleRouteName && moduleRouteName === appRouteName;

export const checkIfEventsMapper = (pathname?: string) => pathname?.includes('/analytics/events');

export const checkIfGameEvents = (pathname: string) => pathname.includes('/liveops/events');

export const checkIfIsAppTimeline = (pathname: string) => pathname.includes(APP_TIMELINE_ROUTE_NAME);

export const checkIfSocial = (pathname: string) => pathname.includes('/social/');

export const checkIfCanBeModuleClass = (pathname: string): boolean =>
  !checkIfSocial(pathname) && !checkIfGameEvents(pathname);

export const checkIfCanHasTimeline = (pathname: string): boolean =>
  !checkIfIsSupportModule(pathname) && !checkIfEventsMapper(pathname);
