import { FC, ChangeEvent, MouseEvent, memo, useState, useEffect, useMemo } from 'react';
import { Button, Checkbox, Divider, FormControlLabel, Popover } from '@mui/material';

import { Class as NotificationClass } from '@playq/octopus-notifications';

import { ClassPickerProps } from './types';
import { Wrapper, Items, SubmitButton } from './styles';

const notificationClasses = Object.values(NotificationClass);
const systemNotificationClasses = [
  NotificationClass.Auth,
  NotificationClass.Users,
  NotificationClass.Groups,
  NotificationClass.Perms,
  NotificationClass.WebEvents,
  NotificationClass.Files,
] as string[];
export const nonSystemNotificationClasses = notificationClasses.filter(
  (notificationClass) => !systemNotificationClasses.includes(notificationClass)
);

export const ClassPicker: FC<ClassPickerProps> = memo(({ selectedItems: selectedItemsProp, onChange, size }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedItems, setSelectedItems] = useState<string[]>(selectedItemsProp);

  useEffect(() => {
    setSelectedItems(selectedItemsProp);
  }, [selectedItemsProp]);

  const selectionInfo = useMemo(() => {
    const allSelected = selectedItems.length === notificationClasses.length;
    return {
      allSelected,
      someSelected: !allSelected && selectedItems.length > 0,
      allSystemSelected: systemNotificationClasses.every((notificationClass) =>
        selectedItems.includes(notificationClass)
      ),
    };
  }, [selectedItems]);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setSelectedItems(selectedItemsProp);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { value } = event.target;
    setSelectedItems((prevSelectedItems) => {
      if (checked) {
        return [...prevSelectedItems, value];
      }
      return prevSelectedItems.filter((item) => item !== value);
    });
  };

  const handleToggleAll = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setSelectedItems(notificationClasses);
    } else {
      setSelectedItems([]);
    }
  };

  const handleToggleSystem = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, ...systemNotificationClasses]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((notificationClass) => !systemNotificationClasses.includes(notificationClass))
      );
    }
  };
  const handleSubmit = () => {
    onChange(selectedItems);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'class-picker-popover' : undefined;

  return (
    <>
      <Button onClick={handleOpen} size={size} color='primary' sx={{ whiteSpace: 'nowrap' }}>
        {selectedItemsProp.length} of {notificationClasses.length}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Wrapper>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectionInfo.allSelected}
                indeterminate={selectionInfo.someSelected}
                onChange={handleToggleAll}
                size='small'
                color='primary'
              />
            }
            label='Select/Unselect All'
            labelPlacement='end'
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={selectionInfo.allSystemSelected}
                onChange={handleToggleSystem}
                size='small'
                color='secondary'
              />
            }
            label='System'
            labelPlacement='end'
          />
          <Divider />
          <Items>
            {notificationClasses.map((notificationClass) => (
              <FormControlLabel
                key={notificationClass}
                control={
                  <Checkbox
                    checked={selectedItems.includes(notificationClass)}
                    value={notificationClass}
                    onChange={handleChange}
                    size='small'
                    color='primary'
                  />
                }
                label={notificationClass}
                labelPlacement='end'
              />
            ))}
          </Items>
          <Divider />
          <SubmitButton onClick={handleSubmit} variant='contained' color='primary' data-testid='button-save'>
            Apply
          </SubmitButton>
        </Wrapper>
      </Popover>
    </>
  );
});
