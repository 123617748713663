import { CSSProperties, SetStateAction } from 'react';
import { NotificationRecord } from 'common/AppTimeline/Timeline/NotificationRow';

import { NotificationsSort, Class as NotificationClass } from '@playq/octopus-notifications';
import { Filter } from '@playq/octopus-common';
import { OffsetLimit } from '@playq/services-shared';

import { BuzzerEntityNotifications } from '/common/buzzer';

import type { TimelineModule, TimelineService } from '../TimelineModules/types';

export type TimelineProps = {
  state: TimelineState;
  onChange: (state: SetStateAction<TimelineState>) => void;
  onQueryReset: () => void;
  service: TimelineService;
  matchNotification?: (notification: BuzzerEntityNotifications) => boolean;
  isEntityTimeline?: boolean;
  isEntitiesTimeline?: boolean;
  searchMode?: boolean;
  fullscreen?: boolean;
  className?: string;
  widgetMode?: boolean;
  notifications?: NotificationRecord[];
  maxTimelineHeight?: number;
  style?: CSSProperties;
  // Used for skeleton height
  skeletonSize?: number;
};

export type TimelineState = {
  query: {
    sortBy: NotificationsSort[];
    filterBy: {
      [key: string]: Filter;
    };
    iterator: OffsetLimit;
  };
  groupBy: string;
  expandedItems: string[];
};

export type TimelineInfo = {
  timelineModule?: ReturnType<TimelineModule>;
  moduleClass?: NotificationClass;
  hasTimelineModule: boolean;
  moduleRouteName: string;
};

export enum TimelineMode {
  Page = 'Page',
  SideBar = 'SideBar',
}

export type UseTimelineParams = {
  pathname: string;
  setTimelineState: (value: React.SetStateAction<TimelineState>) => void;
  mode?: TimelineMode;
  initialQuery?: Partial<TimelineState['query']>;
};
