import { ChangeEvent, FC, FormEvent, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Button, Divider, IconButton, TextField, Typography, Stack } from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';

import { formatDate } from '/helpers';

import { AnnotationsProps } from './types';
import { DialogContentStyled, DialogStyled, DialogTitleStyled, Form, Message, Username } from './styles';

export const Annotations: FC<AnnotationsProps> = ({ annotations, onSubmit, open, onClose }) => {
  const [message, setMessage] = useState('');
  const annotationsEndRef = useRef<HTMLDivElement | null>(null);

  const sortedAnnotations = useMemo(() => annotations.sort((a, b) => a.at.getTime() - b.at.getTime()), [annotations]);

  useLayoutEffect(() => {
    if (open) {
      setTimeout(() => {
        scrollToBottom();
      }, 50);
    }
  }, [open]);

  useEffect(() => {
    scrollToBottom();
  }, [sortedAnnotations]);

  const scrollToBottom = () => {
    annotationsEndRef.current?.scrollIntoView({ behavior: 'auto' });
  };
  const handleMessageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit(message);
    setMessage('');
  };

  return (
    <DialogStyled open={open} onClose={onClose}>
      <DialogTitleStyled>
        <Typography component='h2' variant='h6'>
          Annotations
        </Typography>
        <IconButton onClick={onClose} size='large'>
          <ClearIcon />
        </IconButton>
      </DialogTitleStyled>
      {!!sortedAnnotations.length && <Divider />}
      <DialogContentStyled>
        {sortedAnnotations.length ? (
          <Stack spacing={3}>
            {sortedAnnotations.map((annotation) => (
              <div key={`${annotation.userID.serialize()}${annotation.atAsString}`}>
                <Stack direction='row' spacing={1}>
                  <Username>{annotation.userName}</Username>
                  <Typography variant='body2' color='textSecondary'>
                    {formatDate(annotation.at)}
                  </Typography>
                </Stack>
                <Message>{annotation.message}</Message>
              </div>
            ))}
          </Stack>
        ) : (
          <Typography color='textSecondary'>No annotations yet</Typography>
        )}
        <div ref={annotationsEndRef} />
      </DialogContentStyled>
      {!!sortedAnnotations.length && <Divider />}
      <Form onSubmit={handleSubmit}>
        <TextField
          value={message}
          onChange={handleMessageChange}
          fullWidth={true}
          required={true}
          placeholder='Add an annotation'
        />
        <Button color='primary' variant='contained' type='submit'>
          Add
        </Button>
      </Form>
    </DialogStyled>
  );
};
