import { Button, styled } from '@mui/material';

export const ButtonStyled = styled(Button)`
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  z-index: 1;
  margin: 0 auto;
  border-radius: 18px;
  white-space: nowrap;
`;
