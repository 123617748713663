import { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CardHeader, IconButton, Card, Slide, CardContent } from '@mui/material';
import {
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material';
import classNames from 'classnames';
import { Subject } from 'rxjs';

import { Markdown } from '/shared/Markdown';
import { uiToolkit } from '/store';

import { useHelperStyles } from './styles';

export interface IHelperProps {
  path?: string;
  onClose?: () => void;
}

export interface IHelperWrapperProps extends IHelperProps {
  open: boolean;
}

const helperSubject = new Subject<IHelperWrapperProps>();

export const openHelper = (props: IHelperProps) => {
  helperSubject.next({ ...props, open: true });
};

export const Helper: FC = () => {
  const [props, setProps] = useState<IHelperWrapperProps>({
    open: false,
  });
  const [expanded, setExpanded] = useState<boolean>(false);

  const navSidebarOpen = useSelector(uiToolkit.selectors.navSidebarOpen);
  const classes = useHelperStyles();

  useEffect(() => {
    const sub = helperSubject.subscribe((nextProps: IHelperWrapperProps) => setProps(nextProps));
    return () => sub.unsubscribe();
  }, []);

  const toggleFullMode = () => setExpanded(!expanded);
  const handleClose = () => {
    setProps({
      ...props,
      open: false,
    });

    if (props.onClose) {
      props.onClose();
    }

    setExpanded(false);
  };

  const expandIcon = expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />;

  return (
    <Slide direction='up' in={props.open}>
      <div className={classNames(classes.helper, navSidebarOpen ? classes.sidebarOpen : classes.sidebarClosed)}>
        <Card
          classes={{
            root: classNames(classes.helperCard, expanded ? classes.helperFullHeight : classes.helperHalfHeight),
          }}
        >
          <CardHeader
            classes={{
              root: classes.header,
              title: classes.title,
            }}
            title={
              <>
                <HelpOutlineIcon color='primary' />
                <span>Helpful info</span>
              </>
            }
            action={
              <>
                <IconButton onClick={toggleFullMode} size='large'>
                  {expandIcon}
                </IconButton>
                <IconButton onClick={handleClose} size='large'>
                  <CloseIcon />
                </IconButton>
              </>
            }
          />
          <CardContent
            classes={{
              root: classNames(classes.content, expanded ? classes.contentFullHeight : classes.contentHalfHeight),
            }}
          >
            {props.path && <Markdown path={props.path} />}
          </CardContent>
        </Card>
      </div>
    </Slide>
  );
};
