import { styled } from '@mui/material';

import { ISidebarProps, Sidebar } from '/shared/Sidebar/';
import { sidebarClasses } from '/shared/Sidebar/styles';

import { Timeline } from './Timeline';

export const TIMELINE_WIDTH = 360;
export const MIN_TIMELINE_WIDTH = 160;

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export const ControlBar = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto auto auto auto;
  column-gap: ${(props) => props.theme.spacing(1)};
  align-items: center;
  justify-items: flex-start;
  min-height: 54px;
  padding: ${(props) => props.theme.spacing(1)};
  padding-top: ${(props) => props.theme.spacing(2)};
`;

export const TimelineStyled = styled(Timeline)<{ hide?: boolean }>`
  flex: 1;
  ${(props) =>
    props.hide &&
    `
      display: none;
    `}
`;

export const StyledSidebar = styled(Sidebar)<ISidebarProps & { $width: number }>`
  flex-shrink: 0;
  position: sticky;
  top: 0;
  height: 100vh;
  width: ${({ manualTogglingParams, $width }) => (manualTogglingParams?.isOpenedManually ? $width : 0)}px;
  transition: ${({ theme: { transitions } }) =>
    transitions.create('width', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    })};

  &.${sidebarClasses.sidebarOpen} {
    transition: ${({ theme: { transitions } }) =>
      transitions.create('width', {
        easing: transitions.easing.easeOut,
        duration: transitions.duration.enteringScreen,
      })};
  }

  & .MuiDrawer-paper {
    position: absolute;
    width: 100%;
    padding-top: 48px;
  }
`;
