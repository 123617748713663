import { ChangeEvent, FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  ListSubheader,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Stack,
} from '@mui/material';
import { amber, cyan, green, indigo, teal } from '@mui/material/colors';
import {
  Clear as ClearIcon,
  DateRange as DateRangeIcon,
  Loop as LoopIcon,
  Person as PersonIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
  ViewList as ViewListIcon,
} from '@mui/icons-material';
import { debounce, noop } from 'lodash';

import {
  Environment,
  EnvironmentHelpers,
  FilterExpression,
  OptionsFilter,
  PromotionFilter,
  TextFilter,
  TimeFilter,
} from '@playq/octopus-common';
import { Space } from '@playq/octopus2-apps';
import { NotificationsFilterField } from '@playq/octopus-notifications';

import { useSpaceQuery } from '/api/hooks/spacesService';
import { SpaceSelect } from '/common/Spaces';
import { UserPicker } from '/shared/UserPicker';
import { DateRange, DateRangePicker } from '/shared/DateRangePicker';

import { ToolTip } from '../ToolTip';
import { ClassPicker } from '../ClassPicker';

import { IFilterBarProps } from './types';
import { generalGroupUnits, NONE, timeGroupUnits } from './consts';
import {
  AuthorFilterColumn,
  CheckboxStyled,
  DateFilterColumn,
  EnvSpaceWrapper,
  FilterColumn,
  FilterContent,
  FilterTab,
  GroupByFilterColumn,
  RightActions,
  SelectStyled,
} from './styles';

export const FilterBar: FC<IFilterBarProps> = memo(
  ({ query, onChange, onReset, groupBy, onGroupByChange, isEntitiesTimeline, expanded, hide, endAdornment }) => {
    const [activeFilterTab, setActiveFilterTab] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [selectedSpace, setSelectedSpace] = useState<Space | undefined>();
    const [spaceSelectOpen, setSpaceSelectOpen] = useState(false);

    const { envFilter, textFilter, authorFilter, classFilter, dateFilter } = useMemo(() => {
      const anyFilter = query.filterBy[NotificationsFilterField.Any] as TextFilter | undefined;
      setSearchText(anyFilter?.text ?? '');
      return {
        classFilter: query.filterBy[NotificationsFilterField.Class] as OptionsFilter | undefined,
        envFilter: query.filterBy[NotificationsFilterField.Environment] as PromotionFilter | undefined,
        authorFilter: query.filterBy[NotificationsFilterField.Author] as TextFilter | undefined,
        textFilter: anyFilter,
        dateFilter: query.filterBy[NotificationsFilterField.AddedAt] as TimeFilter | undefined,
      };
    }, [query.filterBy]);

    const dateRange: DateRange = [dateFilter?.left || null, dateFilter?.right || null];

    useEffect(() => {
      if (!envFilter?.space) {
        setSelectedSpace(undefined);
      }
    }, [envFilter]);

    useEffect(() => {
      if (authorFilter) {
        setActiveFilterTab(2);
      }
    }, [authorFilter]);

    const classFilterOptions = useMemo(() => classFilter?.options || [], [classFilter]);

    const { data: envFilterSpace } = useSpaceQuery(selectedSpace ? undefined : envFilter?.space);

    const handleActiveTabChange = (index: number) => () => {
      setActiveFilterTab(index);
    };

    const handleDateRangeChange = useCallback(
      (selectedRange: DateRange) => {
        const [from, till] = selectedRange;
        if (!from || !till) {
          return;
        }
        onChange((prevQuery) => ({
          ...prevQuery,
          filterBy: {
            ...prevQuery.filterBy,
            [NotificationsFilterField.AddedAt]: new TimeFilter({
              expression: FilterExpression.InRange,
              left: from.toISOString(),
              right: till.toISOString(),
              precision: undefined,
            }),
          },
        }));
      },
      [onChange]
    );

    const handleEnvToggle = useCallback(
      (env: Environment) => () => {
        onChange((prevQuery) => {
          const prevEnvFilter = prevQuery.filterBy[NotificationsFilterField.Environment] as PromotionFilter | undefined;
          const updatedEnvFilter = new PromotionFilter({
            env: prevEnvFilter?.env.includes(env)
              ? prevEnvFilter.env.filter((e) => e !== env)
              : (prevEnvFilter?.env || []).concat(env),
            space: prevEnvFilter?.space?.serialize(),
          });
          if (updatedEnvFilter.env.length === 0) {
            const { [NotificationsFilterField.Environment]: tmp, ...rest } = prevQuery.filterBy;
            return {
              ...prevQuery,
              filterBy: rest,
            };
          }
          return {
            ...prevQuery,
            filterBy: {
              ...prevQuery.filterBy,
              [NotificationsFilterField.Environment]: updatedEnvFilter,
            },
          };
        });
      },
      [onChange]
    );

    const handleSpaceSelectOpen = () => {
      setSpaceSelectOpen(true);
    };

    const handleSpaceSelectClose = () => {
      setSpaceSelectOpen(false);
    };

    const handleSpaceSelect = useCallback(
      (s?: Space) => {
        setSpaceSelectOpen(false);
        setSelectedSpace(s);
        onChange((prevQuery) => ({
          ...prevQuery,
          filterBy: {
            ...prevQuery.filterBy,
            [NotificationsFilterField.Environment]: new PromotionFilter({
              env: (prevQuery.filterBy[NotificationsFilterField.Environment] as PromotionFilter | undefined)?.env || [],
              space: s?.id.serialize(),
            }),
          },
        }));
      },
      [onChange]
    );

    const updateTextFilter = useMemo(
      () =>
        debounce(
          (text: string) =>
            onChange((prevQuery) => ({
              ...prevQuery,
              filterBy: {
                ...prevQuery.filterBy,
                [NotificationsFilterField.Any]: new TextFilter({
                  text,
                }),
              },
            })),
          500
        ),
      [onChange]
    );

    const handleAuthorChange = useCallback(
      (author: string) => {
        onChange((prevQuery) => {
          if (author.length === 0) {
            const { [NotificationsFilterField.Author]: tmp, ...rest } = prevQuery.filterBy;
            return {
              ...prevQuery,
              filterBy: rest,
            };
          } else {
            return {
              ...prevQuery,
              filterBy: {
                ...prevQuery.filterBy,
                [NotificationsFilterField.Author]: new TextFilter({
                  text: author,
                }),
              },
            };
          }
        });
      },
      [onChange]
    );

    const handleTextFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
      updateTextFilter(e.target.value);
    };

    const handleTextFilterClear = () => {
      setSearchText('');
      onChange((prevQuery) => {
        const { [NotificationsFilterField.Any]: tmp, ...rest } = prevQuery.filterBy;
        return {
          ...prevQuery,
          filterBy: rest,
        };
      });
    };

    const handleGroupByChange = useCallback(
      (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value as string;
        if (generalGroupUnits.includes(value) || timeGroupUnits.includes(value)) {
          onGroupByChange(value);
        }
      },
      [onGroupByChange]
    );

    const handleGroupByReset = () => {
      onGroupByChange(NONE);
    };

    const handleSelectedClassChange = useCallback(
      (classes: string[]) => {
        onChange((prevQuery) => ({
          ...prevQuery,
          filterBy: {
            ...prevQuery.filterBy,
            [NotificationsFilterField.Class]: new OptionsFilter({ options: classes }),
          },
        }));
      },
      [onChange]
    );

    return (
      <div style={hide ? { display: 'none' } : undefined}>
        <Stack direction='row' spacing={1}>
          {!expanded && (
            <>
              <FilterTab
                selected={activeFilterTab === 0}
                active={true}
                mainColor={green[500]}
                data-testid='date-filter'
              >
                <ToolTip title='Date'>
                  <IconButton onClick={handleActiveTabChange(0)} disableRipple={true} size='small'>
                    <DateRangeIcon />
                  </IconButton>
                </ToolTip>
              </FilterTab>
              <FilterTab
                selected={activeFilterTab === 1}
                active={!!envFilter}
                mainColor={cyan[500]}
                data-testid='promotion-filter'
              >
                <ToolTip title='Environment'>
                  <IconButton onClick={handleActiveTabChange(1)} disableRipple={true} size='small'>
                    <SettingsIcon />
                  </IconButton>
                </ToolTip>
              </FilterTab>
              <FilterTab
                selected={activeFilterTab === 2}
                active={!!authorFilter && authorFilter?.text?.length > 0}
                mainColor={amber[500]}
                data-testid='author-filter'
              >
                <ToolTip title='Author'>
                  <IconButton onClick={handleActiveTabChange(2)} disableRipple={true} size='small'>
                    <PersonIcon />
                  </IconButton>
                </ToolTip>
              </FilterTab>
              <FilterTab
                selected={activeFilterTab === 3}
                active={!!textFilter && textFilter.text.length > 0}
                mainColor={indigo[500]}
                data-testid='search-filter'
              >
                <ToolTip title='Search'>
                  <IconButton onClick={handleActiveTabChange(3)} disableRipple={true} size='small'>
                    <SearchIcon />
                  </IconButton>
                </ToolTip>
              </FilterTab>
              <FilterTab
                selected={activeFilterTab === 4}
                active={groupBy !== NONE}
                mainColor={teal[500]}
                data-testid='group-filter'
              >
                <ToolTip title='Group'>
                  <IconButton onClick={handleActiveTabChange(4)} disableRipple={true} size='small'>
                    <ViewListIcon />
                  </IconButton>
                </ToolTip>
              </FilterTab>
            </>
          )}
          <RightActions data-testid='viewed-areas'>
            {!isEntitiesTimeline && !expanded && (
              <ClassPicker selectedItems={classFilterOptions} onChange={handleSelectedClassChange} />
            )}
            {!expanded && (
              <ToolTip title='Reset Filters' placement='bottom'>
                <IconButton onClick={onReset} size='small'>
                  <LoopIcon fontSize='small' />
                </IconButton>
              </ToolTip>
            )}
            {endAdornment}
          </RightActions>
        </Stack>
        <FilterContent $expanded={expanded}>
          {(activeFilterTab === 0 || expanded) && (
            <DateFilterColumn>
              <DateRangePicker
                dateRange={dateRange}
                onDateRangeChange={handleDateRangeChange}
                onClose={noop}
                singleMonth={true}
                anchorDirection='left'
                displayFormat='DD MMM YY HH:mm'
              />
            </DateFilterColumn>
          )}
          {(activeFilterTab === 1 || expanded) && (
            <FilterColumn>
              <Stack direction='row' spacing={0.5}>
                <Stack spacing={0}>
                  {EnvironmentHelpers.all.map((env) => (
                    <FormControlLabel
                      key={env}
                      control={
                        <CheckboxStyled
                          checked={envFilter?.env.includes(env) || false}
                          onChange={handleEnvToggle(env)}
                          size='small'
                        />
                      }
                      label={env}
                    />
                  ))}
                </Stack>
                {envFilter?.env.includes(Environment.Sandbox) && (
                  <EnvSpaceWrapper>
                    Space:{' '}
                    <Link onClick={handleSpaceSelectOpen}>
                      {envFilterSpace?.name || selectedSpace?.name || 'Default'}
                    </Link>
                  </EnvSpaceWrapper>
                )}
              </Stack>
            </FilterColumn>
          )}
          {(activeFilterTab === 2 || expanded) && (
            <AuthorFilterColumn>
              <UserPicker
                name={authorFilter?.text ?? ''}
                onChange={handleAuthorChange}
                TextFieldProps={{
                  size: expanded ? 'medium' : 'small',
                  variant: expanded ? 'outlined' : 'standard',
                }}
                hideAccessError={true}
              />
            </AuthorFilterColumn>
          )}
          {(activeFilterTab === 3 || expanded) && (
            <FilterColumn>
              <TextField
                data-testid='input-filter'
                value={searchText}
                onChange={handleTextFilterChange}
                fullWidth={true}
                label='Search'
                size={expanded ? 'medium' : 'small'}
                variant={expanded ? 'outlined' : 'standard'}
                InputProps={{
                  endAdornment: searchText && (
                    <InputAdornment position='end'>
                      <IconButton onClick={handleTextFilterClear} size='small'>
                        <ClearIcon fontSize='small' />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FilterColumn>
          )}
          {(activeFilterTab === 4 || expanded) && (
            <GroupByFilterColumn $expanded={expanded}>
              <Stack direction='row' spacing={2}>
                {!expanded && <InputLabel htmlFor='grouped-select'>Grouping</InputLabel>}
                <FormControl variant={expanded ? 'outlined' : 'standard'} fullWidth={expanded}>
                  {expanded && <InputLabel>Group By</InputLabel>}
                  <SelectStyled
                    value={groupBy}
                    onChange={handleGroupByChange}
                    label={expanded ? 'Group By' : null}
                    endAdornment={
                      groupBy !== NONE && (
                        <InputAdornment position='end' sx={{ mr: 2 }}>
                          <IconButton onClick={handleGroupByReset} size='small'>
                            <ClearIcon fontSize='small' />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                    id='grouped-select'
                  >
                    {generalGroupUnits.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                    <ListSubheader>Time</ListSubheader>
                    {timeGroupUnits.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </SelectStyled>
                </FormControl>
              </Stack>
            </GroupByFilterColumn>
          )}
          {expanded && (
            <Button onClick={onReset} endIcon={<LoopIcon />}>
              RESET FILTERS
            </Button>
          )}
        </FilterContent>
        {spaceSelectOpen && (
          <SpaceSelect activeSpaceID={envFilter?.space} onSelect={handleSpaceSelect} onClose={handleSpaceSelectClose} />
        )}
      </div>
    );
  }
);
