import { KBarProvider } from 'kbar';
import { useSelector } from 'react-redux';

import { authToolkit } from '/store';
import { FCWithChildren } from '/common/models';
import { CommandBar } from '/common/Kbar/CommandBar';

export const KBarCustomProvider: FCWithChildren = ({ children }) => {
  const loggedIn = useSelector(authToolkit.selectors.loggedIn);

  return loggedIn ? (
    <KBarProvider
      options={{
        enableHistory: true,
      }}
    >
      <CommandBar />
      {children}
    </KBarProvider>
  ) : (
    <>{children}</>
  );
};
