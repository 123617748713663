import { FC } from 'react';
import { CircularProgress, Stack } from '@mui/material';

import { NotificationWithAnnotations } from '@playq/octopus-notifications';

import { INotificationRowProps } from './types';
import { NotificationGroup } from './NotificationGroup';
import { NotificationItem } from './NotificationItem';

export const NotificationRow: FC<INotificationRowProps> = ({ data, index }) => {
  const { records, expandedItems, isEntityTimeline, onToggle, fullscreen, onAnnotationSubmit, onUserSelect } = data;
  const record = records[index];

  return (
    <div>
      {record !== undefined ? (
        record instanceof NotificationWithAnnotations ? (
          <NotificationItem
            item={record}
            records={records}
            isEntityTimeline={isEntityTimeline}
            fullscreen={fullscreen}
            onAnnotationSubmit={onAnnotationSubmit}
            onUserSelect={onUserSelect}
          />
        ) : (
          <NotificationGroup group={record} expandedItems={expandedItems} onToggle={onToggle} />
        )
      ) : (
        <Stack
          spacing={1}
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={{
            height: '100%',
          }}
        >
          Loading... <CircularProgress size={20} />
        </Stack>
      )}
    </div>
  );
};
