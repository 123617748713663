import { useCallback, useMemo } from 'react';
import { useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query';

import { Either } from '@playq/irt';

import { removeQueriesExceptExcluded } from '/helpers';

import { unwrapEither } from './unwrapEither';
import { UseMutationOptionsExtended } from './types';

export const useEitherMutation = <ArgsType, LeftType, RightType, ContextType>(
  mutationFn: (...args: ArgsType[]) => Promise<Either<LeftType, RightType>>,
  options?: UseMutationOptionsExtended<RightType, LeftType | Error, ArgsType, ContextType>
) => {
  const {
    onSuccess,
    removeQueriesKeys,
    removeQueriesKeysArray = [],
    excludedRemoveQueriesKeys,
    mutationKey,
  } = options ?? {};

  const queryClient = useQueryClient();
  const isMutating = useIsMutating({
    mutationKey,
    predicate: () => mutationKey !== undefined,
  });

  const handleSuccess = useCallback(
    (data: RightType, variables: ArgsType, context: ContextType | undefined) => {
      onSuccess?.(data, variables, context);
      if (isMutating <= 1) {
        removeQueriesExceptExcluded({
          queryClient,
          removeQueriesKeysArray: removeQueriesKeys ? [removeQueriesKeys] : removeQueriesKeysArray,
          excludedQueryKey: excludedRemoveQueriesKeys,
        });
      }
    },
    [queryClient, excludedRemoveQueriesKeys, removeQueriesKeys, removeQueriesKeysArray, isMutating, onSuccess]
  );

  const extendedOptions = useMemo(() => {
    if (options === undefined) {
      return;
    }

    return {
      ...options,
      onSuccess: handleSuccess,
    };
  }, [options, handleSuccess]);

  return useMutation((...args) => unwrapEither(mutationFn(...args)), extendedOptions);
};
